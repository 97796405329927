
import moment from 'moment'


const maximum_date = moment().format('YYYY/MM/DD');
const minimum_date = moment('1900-01-01').format('YYYY/MM/DD');


// validate phone number for mobile phone (010-0000-0000) or telephone (051-000-0000)
const validMobile = (value) =>{
    if (!value) return false;
    
    if(value.includes('-')){
        const reg_mobile_phone = /^\d{2,3}-\d{3,4}-\d{4}$/;
        const mobile_matches = reg_mobile_phone.exec(value);
        return (mobile_matches !=null);
    }else{
        const reg_mobile_phone = /^\d{9,12}$/;
        const mobile_matches = reg_mobile_phone.exec(value);
        return (mobile_matches !=null);
    }

}

// 시작: cusotom validator


// validate date format beetween minimum_date (1920-01-01) and maximum_date (getToday())
const validDate = (value) =>{
    if (!value) return true;
    //alert ('validDate value:'+value)
    const argument_date = moment(value).format('YYYY/MM/DD')
    // alert ('validDate/argument_date:'+argument_date)
    // alert ('validDate/minimum_date:'+minimum_date)
    // alert ('validDate/maximum_date:'+maximum_date)
    return ((minimum_date <= argument_date) && (maximum_date >= argument_date));

}
// validate age format more equal than 0
const validAge = (value) =>{
    if (!value) return true;
    //alert ('validDate value:'+value)

    // alert ('validDate/argument_date:'+argument_date)
    // alert ('validDate/minimum_date:'+minimum_date)
    // alert ('validDate/maximum_date:'+maximum_date)
    return (value >= 0);

}

// validate phone number for mobile phone (010-0000-0000) or telephone (051-000-0000)
const validPhoneNumber = (value) =>{
    if (!value) return true;

    //if (validMobile(value))
    const reg_mobile_phone = /^\d{3}-\d{4}-\d{4}$/;
    const mobile_matches = reg_mobile_phone.exec(value);

    const reg_tel_number = /^\d{2,3}-\d{3,4}-\d{4}$/;
    const tel_matches = reg_tel_number.exec(value);

    const mobile_result = (mobile_matches !=null);
    const tel_result = (tel_matches !=null);

    return (mobile_result || tel_result)
}

const validateSMSCode = (value)=>{
    if (!value) return true;
    const reg_sms_code = /^\d{4}$/;
    const sms_code_match = reg_sms_code.exec(value);
    return (sms_code_match !=null);
}



const validEmail = (value)=> {
    if (!value) return true;

    let re_email = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    //let re_email = /[a-zA-Z0-9]&#x2B;@[a-z]&#x2B;\.[a-z]{2,3}/
    const email_matches = re_email.exec(value);
    const email_result = (email_matches !=null);

    return email_result;
}

// validation관련 정보 전달 한수들
function getMaximumDate(){
    return maximum_date;
}
function getMinimumDate(){
    return minimum_date
}

// _val_status: vuelidator 객체 ['$error'] 포함해야 함
// _word: 출력될 메시지 항목
// _refele: this.$refs 에서 참조 가능한 element 객체
function run_validate_value(_val_status, _word, _refele){
    if (_val_status['$error']){
        alert (_word+ ' 정보 입력에 문제가 있습니다. ');
        _refele.focus();
        return false;
    }
    return true;
}



// 끝: cusotom validator
//-----------------------------------------------------

export {validateSMSCode, validEmail, validMobile, validDate, validPhoneNumber,validAge,getMaximumDate,getMinimumDate, run_validate_value}
