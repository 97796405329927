import api from '@/services/base/index.js'


// userEmail:userInfo.data.data.email,
//               hospitalName: userInfo.data.hospital_name,
//               doctorMajor: userInfo.data.doctormajor,
//               doctorName: userInfo.data.usernmae,
//               phoneNumber:userInfo.data.phone_number,
//               password: loginObj.password,  // from loginObj
//               role:userInfo.data.data.role
const state = {
  // userInfo: null,
  isLogin: false,
  userEmail:'',
  password:'',
  hospitalName:'',

  doctorMajor:'',
  doctorName:'',
  phoneNumber:'',

  hospitalCode:'',

  role:'',
}

// export default new Vuex.Store({
const userStore = {
  namespaced: true,
  state,
  mutations:{
    setStatusLogin(state,loginStatusInfo){
      state.userEmail = loginStatusInfo.userEmail
      state.hospitalName = loginStatusInfo.hospitalName
      state.password = loginStatusInfo.password
      state.role = loginStatusInfo.role

      state.doctorMajor = loginStatusInfo.doctorMajor
      state.doctorName = loginStatusInfo.doctorName
      state.phoneNumber = loginStatusInfo.phoneNumber

      state.hospitalCode = loginStatusInfo.hospitalCode

      state.isLogin = true;

      // alert ('userStore:mutations:setLoginStatus with loginStatus:' +JSON.stringify(state))
      //console.log('userStore:mutations:setLoginStatus with loginStatus:' +JSON.stringify(state))
    },
    setStatusLogout(state){

      //alert ('userStore.setStatusLogout()')
      console.log('userStore:mutations:setStatusLogout()')
      state.userEmail = ''
      state.hospitalName = ''
      state.password = ''
      state.isLogin = false
      state.role = ''

      state.doctorMajor = ''
      state.doctorName = ''
      state.phoneNumber = ''

      state.hospitalCode =''


    }

  },
  getters:{
    getUserHospitalCode(state){
      return state.hospitalCode
    },
    getUserDoctorMajor(state){
      return state.doctorMajor
    },
    getUserDoctorName(state){
      return state.doctorName
    },
    getUserPhoneNumber(state){
      return state.phoneNumber
    },
    getUserEmail(state){
      //nalert('store:getUserEmail:'+JSON.stringify(state.userInfo))
      return state.userEmail

    },
    getUserPassword(state){
      return state.password
    },
    getUserHospitalName(state){
      //alert('store:getUserHospitalName:'+JSON.stringify(state.hospital_name))
      return state.hospitalName
    },
    isLogin(state){
      //alert ('userStore:isLogin() =>'+state.isLogin)
      return state.isLogin;
    },
    getLoginObj(state){
      return {'email':state.userEmail,'password':state.password,'role':state.role}
    },
    isManager(state){
      if (state.role == "A"){
        return true;
      }
      else{
        return false;
      }
    }

  },
  actions: {
    // async getOmimList({getters}){
    //   try{
    //     //alert ('userStore:getOmimList');
    //     let loginObj = getters.getLoginObj
    //     //alert ("userStore:getOmimList with loginObj:"+JSON.stringify(loginObj))

    //     const omimList = await api.getOmimList(loginObj);

    //     if (omimList != null){
    //       console.log('userStore is success ')
    //       alert ("userStore:getOmimList with loginObj:"+JSON.stringify(omimList))
    //       return omimList;
    //     }
    //     return false
    //   }
    //   catch (err) {
    //     console.error(err)
    //     alert(err)
    //     return false
    //   }
    // },
    async login({commit}, loginObj){
      try{
        const loginRes = await api.login(loginObj);
        // alert ('userStore:actions:login:api.login:loginRes:'+JSON.stringify(loginRes)+',loginObj:'+JSON.stringify(loginObj))
// loginRes
//        .data:{
//            "token":"token value"
//        }
        const loginToken = loginRes.data.token
        if (loginToken != ''){
          const userInfo = await api.getUserInfo(loginObj,loginToken);
          //alert ('userStore:actions:login:api.getUserInfo.userInfo:'+JSON.stringify(userInfo))

    // userInfo
    //    .data{"result":"success",
    //          data:{"username": "류동성",
    //                "hospital": 1,
    //                "email": "bluesky4383@gmail.com",
    //                "phone_number": "01066009949"},
    //    .     "hospital_name":"가천대 길병원(진단검사의학과)"

          if (userInfo.data.result =="success"){
            //make loginObj for mutations:login
            let loginStatusInfo  = {
              userEmail:userInfo.data.data.email,
              hospitalName: userInfo.data.hospital_name,
              hospitalCode: userInfo.data.hospital_code,
              doctorMajor: userInfo.data.data.doctormajor,
              doctorName: userInfo.data.data.username,
              phoneNumber:userInfo.data.data.phone_number,
              password: loginObj.password,  // from loginObj
              role:userInfo.data.data.role
            }
            //alert ('loginStatus:'+JSON.stringify(loginStatus))
            commit('setStatusLogin',loginStatusInfo);

            //
            const loggedObj = this.getters['userStore/getLoginObj'];
            //// alert ('userStore:login: loginObj from this.getters[userStore/getLoginObj]:'+JSON.stringify(loggedObj))
            //commit('patientReqStore/setLoginObj',loggedObj)


            return loggedObj
          }

          return false
        }
        return false
      }
      catch (err) {
        console.error(err)
        alert(err)
        return false
      }
    },
    async logout({commit}){
      //alert ('userStore:logout')
      commit("setStatusLogout");
      return api.logout()
    },
  }
}

export default userStore;

