
//import Vue from 'vue'
import Vuex from 'vuex'

//Vue.use(Vuex)
import userStore from './userStore.js'
import constStore from './constStore.js'
import patientReqStore from './patientReq.js'
import createPersistedState from 'vuex-persistedstate'

const store = new Vuex.Store({
    modules:{
        userStore: userStore,
        constStore: constStore,
        patientReqStore: patientReqStore,
    },
    //vuex plugin 명시
    plugins:[createPersistedState({
        paths:["userStore"],

    })]
})

export default store;