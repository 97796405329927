<template>
    <div>
        <!-- 시작: 조회 -->
        <div class="row">
            <div class="col">
                <font style="font-weight:bold;font-style:normal;font-size:1.2em" >- 진단 검사 결과 관리 -</font>
            </div>
        </div>
        <div class="row">
            <div class="col" style="height:10px">
                
            </div>
        </div>

        <div class="row ">
            <div class="col  col-md-12" >
                <!-- 시작: 새로운 추가 검사 입력 -->
                <div class="container">
                    <div class="row">
                        <div class="col" align="center">
                            <font size="2"><b>추가 검사</b></font>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label for="text_inspect_date" class="col-4 label-search-center" style="width:60px" ><b>날짜</b></label>
                             &nbsp; 
                            <input type="date" 
                                placeholder="YYYY/MM/DD" id="text_inspect_date" name="text_inspect_date" v-model="this.fields.inspect_date" 
                                :min="store.getters['constStore/getMinDate']" 
                                :max="store.getters['constStore/getMaxDate']" 
                                class="input-search" style="width:90px;height:28px">
                        </div>
                        <div class="col">
                            <label for="sel_seq_type" class="col-4 label-search-center" style="width:60px" ><b>Seq. 유형</b></label>
                            &nbsp;
                            <select id="sel_seq_type" name="sel_seq_type" v-model="this.fields.seq_type" class="form-select-sm select-search"  style="width:100px;height:28px" >
                                <option value="" selected>-</option>
                                <option value="WGRS">WGRS</option>
                                <option value="WES">WES</option>
                            </select>
                        </div>
                        <div class="col">
                            <label for="sel_seq_report" class="col-4 label-search-center" style="width:60px" ><b>Seq. 결과</b></label>
                            &nbsp;
                            <select id="sel_seq_report" name="sel_seq_report" v-model="this.fields.seq_report" class="form-select-sm select-search"  style="width:100px;height:28px" >
                                <option value="" selected>-</option>
                                <option value="NEG">Negative</option>
                                <option value="POS">Positive</option>
                                <option value="INC">Inconclusive</option>
                            </select>
                        </div>
                        
                        <div class="col">
                            <label for="sel_final_report" class="col-4 label-search-right" style="width:60px"><b>최종 보고</b></label>
                            &nbsp;
                            <select id="sel_final_report" name="sel_final_report" v-model="this.fields.final_report" class="form-select-sm select-search"  style="width:100px;height:28px" >
                                <option value="" selected>-</option>
                                <option value="NEG">Negative</option>
                                <option value="POS">Positive</option>
                                <option value="INC">Inconclusive</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col" style="height:10px">

                        </div>
                    </div>
                    <div class="row">
                        <div class="col" style="width:120px;">

                        </div>
                        
                        <div class="col" style="width:20px;">

                        </div>
                        <div class="col">
                            <b>
                                <button href="#" class="btn btn-primary btn-sm btn-search-action" id="btn_report_addscreendata" 
                                style="float:right; margin-right: 10px;" @click="this.report_addscreendata(this.id)" disabled="True">보고</button> 
                            </b>
                            <!--b><button href="#" class="btn btn-primary btn-sm btn-search-action" id="btn_init_addscreendata" 
                                style="float:right; margin-right: 10px;" @click="this.init_addscreendata()">초기화</button> 
                            </b-->
                        </div>
                    </div>
                    <div class="row">
                        <div class="col" style="height:30px">

                        </div>
                    </div>
                </div>
                <!-- 끝: 새로운 추가 검사 입력 -->
                <!-- 시작: 기추가 검사 리스트 출력 -->
                <div class="container">
                    <div class="row">
                        <div class="col" align="center">
                            <font size="2"><b>검사 리스트</b></font>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            번호
                        </div>
                        <div class="col">
                            추가 검사 날짜
                        </div>
                        <div class="col">
                            추가 검사 유형
                        </div>
                        <div class="col">
                            추가 검사 결과
                        </div>
                        <div class="col">
                            최종 결과
                        </div>
                    </div>
                    <div class="row" v-for="(data, idx) in this.datalist" :key="idx" style="padding-top: 10px;">
                        <div class="col">
                            {{ idx+1 }}
                        </div>
                        <div class="col">
                            {{ data['inspect_date']  }}
                        </div>
                        <div class="col">
                            {{ data['seq_type']  }}
                        </div>
                        <div class="col">
                            {{ data['seq_report']  }}
                        </div>
                        <div class="col">
                            {{ data['final_report']  }}
                        </div>
                    </div>
                </div>
                
                <!-- 끝: 기추가 검사 리스트 출력 -->
                
                    
            </div>
        </div>
        <!-- 끝: 조회 옵션 설정 -->


        <div class="row" >
            <div class="col" style="height:30px;background-color: #ffffff">
            </div>
        </div>
        <!-- 끝: 조회 후 공백 설정 -->
    </div>

    <!-- 끝: 조회 -->
</template>
<script>
import {reactive, computed} from 'vue'

import { useStore } from 'vuex'

import api from '@/services/base/index.js'

import moment from 'moment'

import useVuelidate from '@vuelidate/core'
// import {isProxy, toRaw} from 'vue';


const isValidDate = (value) => {
    
    
    if (value == ""){ // 유효한 날짜를 입력 안했거나 공백임
        return true
    }
    else{
        const maximum_date = moment().format('YYYY/MM/DD');
        const minimum_date = moment('1900-01-01').format('YYYY/MM/DD');
        var date = moment(value).format('YYYY/MM/DD');
        if (date > minimum_date && date <= maximum_date){
            return true
        }
        alert ('유효한 날짜를 입력해주세요. 입력한 날짜:'+date.toString())
        return false
    }
};
export default {
    name: 'AddScreenData',
    props:[
        'id',
        'listdata'
    ],
    data() {
       
        let fields = reactive({
            seq_report:'', // 추가검사 결과
            inspect_date:'',
            seq_type:'',// 추가검사유형
            final_result:'', // 최종검사 결과
        });

        const fields_rule = computed(() =>{
            return {
                seq_report: {},
                inspect_date:{isValidDate},
                seq_type: {}, //, isBetween:[10,20]},
                final_result:{}
            };
        });
        // validators declareration for each validation
        const v_fields$ = useVuelidate(fields_rule, fields);


        return {
            fields,
            v_fields$
        }
    },
    // created function
    async created(){

    },
    
    // mounted function
    async mounted() {
        
        // api.getHospitalList()
        // //axios.get('/hospital/') //,axios_config)
        // .then(response => {
        //     // this.user.hospital=null;
        //     this.search_requesting_institution_list = response.data.map( d => ({
        //         hcode: d.hcode,
        //         id:d.id,
        //         name:d.name,
        //         title:d.name
        //     }))
        // }).catch(err=>{
        //     console.log(err)
        // })

    },
    setup(){
        const store = useStore();
        // var temp_basis_info = JSON.parse(localStorage.getItem('pb_info'));
        // alert ('loaded:'+JSON.stringify(temp_basis_info));
        // this.pb_obj = temp_basis_info;

        

        return {store}
    },
    methods:{
        validate(){
            this.v_fields$.$touch()
            //console.log(JSON.stringify(this.v_fields$));
            if (this.v_fields$['$error']){

                //<input type="date"
                //                placeholder="YYYY/MM/DD" id="text_search_date_from" name="text_search_date_from"
                //                v-model="this.fields.search_request_date_start" class="input-search"  style="width:90px;height:28px"
                //                :max="store.getters['constStore/getMaxDate']" v-on:keyup.enter="this.search_list()"
                //                >
                
                //console.log("errors:"+JSON.stringify(this.v_fields$['$errors']))
                //console.log("store.getters['constStore/getMaxDate']:"+ this.store.getters['constStore/getMaxDate']+", this.fields.search_request_date_start:"+this.fields.search_request_date_start)

            }
            return !this.v_fields$['$error']


        },
        report_addscreendata(){

            let addscreendata={
                'patient_id':this.id,
                'addscreen_data': this.fields
            }

            api.reportAddScreenData(addscreendata)
            .then(response => {
                this.listdata.append(
                    response.addscreen_data
                )

            }).catch(err=>{
                console.log(err)
            })
        },
        // search_list(){
        //     //alert('Search::search_list() is called with fields:'+JSON.stringify(this.fields))
        //     if (this.validate()){
        //         //alert('fields_changed will be called')
        //         this.$emit('fields_changed',this.fields)
        //     }
        // },
        get_fields(){
            return this.fields
        }

    }

}
</script>
<style scoped>
</style>
