<template>
    <div>
        <div style="height:40px" />
        <div class="d-flex justify-content-center">
            <div class="d-flex flex-column"  >
                <div class="d-flex justify-content-center" style="width:700px">
                    <p style="font-weight: bold"><i class="fas fa-check"></i>회원 가입 하기</p>
                </div>
                <div class="d-flex justify-content-center" style="width:700px">

                    <div style="height:40px" />
                    <!-- user aggreement -->

                    <div class="d-flex justify-content-center">
                        <div class="d-flex flex-column"  >
                            <div class="d-flex justify-content-left" style="width:700px;height:10px">
                                <p style="font-weight: bold"><i class="fas fa-check"></i> &#x25a0; 개인정보 수집(이용) 및 동의 안내 </p>
                            </div>
                            <div class="d-flex" style="width:700px;height:50px">
                                <div class="p-4 explain">아래 개인정보 수집 동의 안내 사항을 읽어 보시고 동의 부탁드립니다. </div>
                                <div class="ml-auto p-4">
                                    <input type="checkbox"  v-model="user.privacyagreed"  id="agreement"
                                        ref='privacyagreed'
                                    />
                                    <label for="agreement" v-if="user.privacyagreed"> &nbsp; <span class="emphasized"> 동의 </span></label>
                                    <label for="agreement" v-else > &nbsp; <span class="unemphasized"> 동의 </span></label>
                                </div>
                            </div>

                            <div v-if="user.privacyagreed == false"  style="width:700px">
                                <div class="z-depth-1" style="font-size: 13px; background-color: white; border:1px solid #ddd; height:220px;overflow:auto;padding:5px;" >
                                    <dl>
                                        <dt>
                                        1. 개인정보 수집(이용) 목적: 사용자 확인 및 중복 가입 방지
                                        </dt>
                                        <br>
                                        <dt>
                                        2. 수집하려는 항목: 이름, 아이디, 기관명, 휴대폰번호, 기관(부서) 전화번호
                                        </dt>
                                        <br>
                                        <dt>
                                        3. 개인정보의 보유(이용) 기간
                                        </dt>
                                        <dd>
                                        <ol>
                                            - 이름, ID, 기관명 : <b style="color:blue">탈퇴 후 2년*</b>
                                            <br>
                                        <b style="color:blue">* 관련 근거</b>: 개인정보의 안전성 확보 조치 기준 제 8조 (접속 기록의 보관 및 점검)
                                            <br>
                                            - 휴대폰 번호, 기관(부서) 전화번호: <b  style="color:blue">탈퇴 후 즉시 파기</b>
                                        </ol>
                                        </dd>
                                        <dt>
                                        4. 개인정보 수집 및 이용 동의를 거부할 권리
                                        </dt>
                                        <dd>
                                        <ol>
                                            - <b  style="color:blue">이용자는  개인정보의 수집 및 이용 동의를 거부할 권리가 있으며, 개인정보의 이용 및 수집 동의를 거부하실 경우, 사용자 가입이 어려울 수 있습니다.</b>
                                        </ol>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--End of User aggreement -->

                </div>


                <div style="height:40px" />

                <!-- UserSignUp -->
                <div class="d-flex justify-content-center">
                    <div class="d-flex flex-column"  >
                        <div class="d-flex justify-content-left" style="width:700px;height:10px">
                            <p style="font-weight: bold"><i class="fas fa-check"></i> &#x25A0; 회원 정보 입력하기 </p>
                        </div>
                        <div class="d-flex" style="width:700px;height:50px">
                            <div class="p-4 explain">아래 회원 정보에 대해 입력 부탁드립니다.</div>
                            <div class="ml-auto p-4">
                                <input type="checkbox" data-toggle="collapse"
                                    data-target="#collapseUserChecked"
                                    id="userchecked"
                                    v-model="user.confirmed"
                                    ref='confirmed'
                                />
                                <label for="userchecked"
                                    v-if="user.confirmed==true"

                                     > &nbsp; <span class="emphasized">  확인 </span></label>
                                <label for="userchecked" v-else
                                > &nbsp; <span class="unemphasized"> 확인 </span></label>
                            </div>
                        </div>

                        <div class="z-depth-1" style="font-size: 13px; background-color: white; border:1px solid #ddd; height:300px;width:700px;overflow:auto;padding:5px;" >
                        <!--form method="post" action="{% url 'user:create' %}" id="frmCreate" onsubmit="check_user_input(document.getElementById('frmCreate'));"-->
                        <!--form @submit.prevent="subRegistermit" class="needs-validation"-->
                            <form @submit.prevent="onTryUserRegistration">
                                <div class="input-group input-group-sm mb-3">
                                    <span class="input-group-text"  style="width:80px" >성함 </span>
                                    <input v-model="user.username" type="text" class="form-control" placeholder="성함"
                                        required
                                        :class="{error:v$.username.$error}"
                                        @input="v$.username.$touch"
                                        ref='username' />
                                    <span class="input-group-text"  style="width:80px" >의뢰병원 </span>
                                    <select class="form-control" id="hosptial" v-model="user.hospital"
                                        required
                                        :class="{error:v$.hospital.$error}"
                                        ref='hospital'
                                    >
                                        <option disabled selected value> Please select one </option>
                                        <option :key="i" :value="d.hcode" v-for="(d,i) in hospital_info">{{d.title}}</option>
                                    </select>
                                </div>
				<div class = "input-group input-group-sm mb-3" >
                                    * 회원확인을 위해 병원 또는 학교 계정 메일로 입력 부탁드립니다.
                                </div>
                                <div class="input-group input-group-sm mb-3" >
                                    <span class="input-group-text " style="width:80px" >E-mail</span>
                                    <input v-model="user.email" ref='email' type="email" class="form-control" placeholder="Email address" required :class="{error:v$.email.$error}" @input="v$.email.$touch" />
                                    <!--button class="btn btn-outline-secondary" type="button"
                                        v-on:click="sendEmail('register')"
                                    >
                                    Check E-mail
                                    </button-->
                                </div>
                                <div class="input-group input-group-sm mb-3">
                                    <span class="input-group-text"  style="width:80px" >면허번호 </span>
                                    <input v-model="user.license"
                                        ref='license' type="text" class="form-control"
                                        placeholder="Doctor license number" required
                                        :class="{error:v$.license.$error}" @input="v$.license.$touch"
                                        pattern="(\d)+"

                                    />
                                    <span class="input-group-text"  style="width:80px" >전공 </span>
                                    <input v-model="user.doctormajor" ref='doctormajor' type="text" class="form-control" placeholder="임상유전학" required :class="{error:v$.doctormajor.$error}" @input="v$.doctormajor.$touch" />
                                </div>
				<div class="input-group input-group-sm mb-3" style="margin-bottom: 0px!important;">
                                    <span class="input-group-text" style="width:80px" >Password</span>
                                    <input v-model="user.password"
                                        type="password" class="form-control" id="password" name="password"
                                        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                        placeholder="Your password"
					title="최소 하나의 숫자와 하나의 대문자 및 소문자, 최소 8자 이상의 문자를 포함해야 합니다."
                                        required
                                        :class="{error:v$.password.$error}"
                                        @input="v$.password.$touch"
                                        ref = 'password' >
                                    <input v-model="user.confirmpassword"
                                        type="password" class="form-control" id="password2" name="password2"
                                        placeholder="Confirm your password" title="Confirm your password" required
                                        :class="{error:v$.confirmpassword.$error}"
                                        @input="v$.confirmpassword.$touch"
                                        ref='confirmpassword'
                                    >
                                </div>
                                <div class = "input-group input-group-sm mb-3" style="height: 3px;">
                                    * 최소 하나의 숫자와 하나의 대문자 및 소문자, 최소 8자 이상의 문자를 포함해야 합니다.
                                </div>
                                <div class="input-group input-group-sm mb-3" >
                                    <span class="input-group-text" style="width:80px" >휴대폰 번호</span>
                                    <input v-model="user.phone_number"
                                        type='tel'
                                        id='phone_number'
                                        class="form-control"
                                        pattern="[0-9]{3}[-]?[0-9]{3,4}[-]?[0-9]{4}"
                                        required
                                        ref = 'phone_number'
                                        @input='this.type_phone_number()'
                                    > <i class='bx bxs-phone'></i>
                                    <button class="btn btn-outline-secondary" type="button"
                                        id='btn_send_verify_sms'
                                        ref = 'btn_send_verify_sms'
                                        @click.self.prevent="this.send_verify_sms()"

                                        >
                                        SMS인증번호발송
                                    </button>
                                    <span class="input-group-text" style="width:80px" >인증번호</span>
                                    <input v-model="verify.code"
                                        type='text'  class="form-control"
                                        pattern="[0-9]{4}"
                                        required
                                        id='text_verify_sms_code'
                                        ref = 'text_verify_sms_code'

                                    > <i class='bx bxs-phone'></i>
                                    <button class="btn btn-outline-secondary"
                                        id = 'btn_verify_code'
                                        ref = 'btn_verify_code'
                                        type="button"
                                        @click.self.prevent="this.send_veryfy_sms_code()"
                                    >
                                        인증번호확인
                                    </button>
                                </div>
                                <div align="center">
                                    <button type="submit" class="btn btn-secondary btn-sm">회원가입</button>
                                    &nbsp; &nbsp;
                                    <button type="button" class="btn btn-secondary btn-sm"
                                        @click.self.prevent="this.init()"
                                    >초기화</button>
                                </div>

                                <!-- <span v-if="v$.confirmpassword.$error">
                                {{ v$.confirmpassword.$errors[0].$message }}
                                confirmpassword:  {{ v$.confirmpassword.$errors[0].$message }} , email: {{ v$.email}} errors: {{v$.$errors}}
                                </span> -->
                            </form>
                        </div>
                    </div>
                </div>
                <!-- End of UserSignUp -->
            </div>
        </div>
            <!--input v-model="data.name" class="form-control" placeholder="Name" required />
            <input v-model="data.email" type="email" class="form-control" placeholder="Email" required />
            <input v-model="data.password" type="password" class="form-control"  placeholder="Password" required />
            <button class="w-100 btn btn-lg btn-primary" type="submit">Sign in</button-->
    </div>
</template>

<script >

import { ref , reactive, computed } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, email, sameAs, minLength} from "@vuelidate/validators";
import { validMobile , validateSMSCode} from  '@/helper/validator.js'

import {run_validate_value} from  '@/helper/validator.js'


import { useRouter } from 'vue-router'

//import parser from '@/helper/base_parser.js'

import api from '@/services/base/index.js'

export default {
    name:'UserSignUp',
    //emits:['user.privacyagreed'],
    setup(){
        const user = reactive({
            username: '',
            license:'',
            doctormajor:'',
            email: '',
            hospital:'',
            password:'',
            confirmpassword:'',
            phone_number:'',
            privacyagreed:false,
            confirmed:false,
        });
        const user_rules = computed(() =>{
            return {
                username: { required }, // Matches state.firstName
                license:{required},
                doctormajor:{required},
                email: { required, email }, // Matches state.contact.email
                hospital:{required},
                password:{required, minLength: minLength(6) },
                confirmpassword:{ required, sameAs: sameAs(user.password) },
                phone_number:{required,validMobile},
                privacyagreed:{
                    required(val){
                        return val;
                    }
                },
                confirmed:{
                    required(val){
                        return val;
                    }
                }
            };
        });
        const router = useRouter ()

        const verify = reactive({
            code:''
        });
        const verify_rules = computed(()=>{
            return {
                code:{ required, validateSMSCode},

            }
        });

        const sms_verified= ref(false)
        const v_verify$ = useVuelidate(verify_rules, verify)

        const v$ = useVuelidate(user_rules, user);
        const submitted = ref(false);


        return {user, v$, verify, v_verify$, sms_verified, submitted, router}
    },
    data(){
        let hospital_info = reactive([])
        //console.log("data:hospital_info")
        return { hospital_info}
    },
    created(){
        this.init();
        api.getHospitalList()
        //axios.get('/hospital/') //,axios_config)
        .then(response => {
            console.log('received data by API call')
            this.user.hospital=null;
            this.hospital_info = response.data.map( d => ({
                hcode: d.hcode,
                id:d.id,
                name:d.name,
                title:d.name
            }))
            console.log(this.hospital_info)
        }).catch(err=>{
            console.log(err)
        })


    },
    methods:{
        init(){
            this.user.username=''
            this.user.license=''
            this.user.doctormajor=''
            this.user.email=''
            this.user.hospital=''
            this.user.password=''
            this.user.confirmpassword=''
            this.user.phone_number=''
            this.user.privacyagreed=false
            this.user.confirmed=false


            this.verify.code =''
            this.sms_verified = false

            // this.$refs['btn_send_verify_sms'].disabled = true
            // this.$refs['text_verify_phonenumber'].disabled = true
            // this.$refs['btn_verify_code'].disabled = true


        },


        async send_veryfy_sms_code(){

            this.v_verify$.code.$touch()
            this.v$.phone_number.$touch()
            if (this.v_verify$.code.$error || this.v$.phone_number.$error ){
                alert ('유효한 휴대폰 번호 그리고 SMS 인증 번호를 입력해주세요.')
                return false
            }
            //alert('send_veryfy_sms_code')
            api.send_verify_code(this.user.phone_number,this.verify.code)
            .then(res => {
                console.log("success:"+JSON.stringify(res.data));

                let result = res.result

                if (result=='success'){
                    if (res.data['message'] == 'authorized'){
                        this.sms_verified= true

                        alert ('입력하신 SMS 번호가 유효한 번호임을 확인하였습니다. ')
                        // this.$refs['text_verify_sms_code'].disabled =false


                    }
                    else{
                        this.sms_verified= false
                        alert ('입력하신 SMS 번호와 보내드린 인증 번호가 다릅니다. 확인 부탁드립니다.')
                        this.$refs['text_verify_sms_code'].focus()
                    }

                    // 안내 페이지로 이동하게 만들기



                    //this.router.push({name:'UserSignUpNotice'});

                }
                else{
                    alert ('SMS 인증 번호 발송에 문제가 있습니다.');
                    this.$refs['text_verify_sms_code'].focus();
                }
            })

        },
        async send_verify_sms(){

            // alert('send_verify_sms()')
            this.v$.phone_number.$touch()
            if (this.v$.phone_number.$error){
                alert ('유효한 SMS 번호를 입력해주세요.')
                return false
            }

            api.send_verify_sms_code(this.user.phone_number)
            .then(res => {
                console.log("success:"+JSON.stringify(res.data));

                let result = res.result

                if (result=='success'){
                    alert ('SMS 인증 번호가 발송되었습니다. 인증번호 란에 발송된 인증번호 입력 부탁드립니다. ')
                    // 안내 페이지로 이동하게 만들기

                    this.$refs['text_verify_sms_code'].focus()
                    //this.router.push({name:'UserSignUpNotice'});

                }
                else{
                    alert ('SMS 인증 번호 발송에 문제가 있습니다.');
                    this.$refs['phone_number'].focus();
                }
            })

        },
        type_phone_number(){
            this.sms_verified= false
        },

        async onTryUserRegistration(){
            this.submitted = true;

            // stop here if form is invalid
            this.v$.$touch();
            //if (this.v$.$invalid) {
            if (this.v$.$invalid) {
                //alert ('입력 조건들이 맞질 않습니다. 확인부탁드려요.');

                const error_items = ['confirmpassword','doctormajor','email','hospital','license','password','phone_number','privacyagreed','username','confirmed'];
                const error_words = ['암호확인','전공','이메일','의뢰병원','면허번호', '암호','휴대폰 번호', '개인정보동의','성함','확인']
                for (let i=0;i<error_items.length;i++){
                    const f_validator = this.v$[error_items[i]]


                    if (!run_validate_value(f_validator, error_words[i]+" 항목 입력", this.$refs[error_items[i]])){
                            return false;
                    }


                }
                return;
            }

            if (! this.sms_verified){
                alert ('SMS 번호 인증 부탁드립니다.')
                this.$refs['phone_number'].focus()
                return false
            }

            //axios.post(server_addr + '/api/v1/user/signup/',this.v$.data, axios_config

            console.log("Input user_information:" + JSON.stringify(this.user));
            api.signup(this.user)
            .then(res => {
                console.log("success:"+JSON.stringify(res.data));

                let response = res.data



                if (response.result=='success'){
                    alert ('회원 가입이 성공적으로 이루어졌습니다. 가입 승인될 때까지 기다려 주시면 감사하겠습니다. ')
                    // 안내 페이지로 이동하게 만들기


                    this.router.push({name:'UserSignUpNotice'});

                }
                else{
                    alert ('입력하신 Email 혹은 면허 번호 는 이미 가입되어 있어 사용이 불가합니다.');
                    this.$refs['email'].focus();
                }

            }).catch(err => {
                console.log("error:"+err)
                alert ('회원 가입에 문제가 있습니다.')
                return false;
            })


        }
    }
}

</script>

<style>
</style>






