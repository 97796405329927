


//const SMS_CMD_TYPES = ['','GET_VAL_SMS_ARRAY']



export default{
    install(Vue){

        // 시작: EVENT (외부로 알려주기 위한 이벤트) 정의
        // EVT_UPDATED  // 이벤트 발생 CMD에 대한 처리 evt_res_[obj]_cmd 에 대한 처리가 필요한 EVENT
        // EVT_PROCESSED //  이벤트 발생 CMD에 대한 처리 evt_res_[obj]_cmd 에 대한 처리가 필요없는 EVENT
        // EVT_ERROR

        Vue.config.globalProperties.$EVT_TYPES= ['EVT_UPDATED','EVT_PROCESSED','EVT_ERROR']
        Vue.config.globalProperties.$REQ_CRUDMODES=['LIST','ADD','UPDATE','DELETE','DETAIL','UPDATEBYMANAGER'];

        Vue.config.globalProperties.$concat_str=function(a,b){
            return a+b
        }
        Vue.config.globalProperties.$abbreviate=function(_str,_num){
            if (_str.length < _num){
                return _str
            }

            return _str.substr(0,_num) + '...'
        }
        // 환자 의뢰를 위한 patient basic 업로드 파일명
        Vue.config.globalProperties.$pb_upload_filenames=[
            '서식 1. 대상자 설명문 및 동의서(어린이 희귀질환자)',
            '서식 2. 대상자 설명문 및 동의서(어린이 희귀질환자) - 2차적 데이터 공유 보관용',
            '서식 3. 대상자 설명문 및 동의서(미성년 희귀질환자 대리인)',
            '서식 4. 대상자 설명문 및 동의서(미성년 희귀질환자 대리인) - 2차적 데이터 공유 보관용',
            '서식 5. 대상자 설명문 및 동의서(희귀질환자 및 가족)',
            '서식 6. 대상자 설명문 및 동의서(희귀질환자 및 가족)- 2차적 데이터 공유 보관용',
            '서식 7. 인체유래물 동의서',
            '서식 8. 유전자 검사 동의서',
            '가계도',
            '기타 파일'
        ]

        // Vue.config.globalProperties.$pb_essential_files={
        //         'EARLYCHILDHOOD':[false, false, true,false,false,false,false,false, false, false] , // 0 ~6 세 (birth to age 5) [필수업로드파일: 3, 4, 7  - 선택업로드파일:  8 유전자 검사 동의서 는 선택]
        //         'MIDDLECHILDHOOD':[false, true, false, false, false, false, false, false,false, false], // 7 ~ 12세 (ages 6 to 12) [필수업로드파일: 1,2,3,4,7 - 선택업로드파일:  8 유전자 검사 동의서 는 선택]
        //         'ADOLESCENCE':[false, false, false, false, true, false, false, false, false, false], // 13세 이상 (ages 13 to 18 ) [필수 업로드 파일: 5,6,7 - 선택업로드 파일: 8 유전자 검사 동의서는 선택]
        // }

         Vue.config.globalProperties.$pb_essential_files={
                'EARLYCHILDHOOD':[false, false, true,true,false,false,true,true, false, false] , // 0 ~6 세 (birth to age 5) [필수업로드파일: 3, 4, 7  - 선택업로드파일:  8 유전자 검사 동의서 는 선택]
                'MIDDLECHILDHOOD':[true, true, true, true, false, false, true, true,false, false], // 7 ~ 12세 (ages 6 to 12) [필수업로드파일: 1,2,3,4,7 - 선택업로드파일:  8 유전자 검사 동의서 는 선택]
                'ADOLESCENCE':[false, false, false, false, true, true, true, true, false, false], // 13세 이상 (ages 13 to 18 ) [필수 업로드 파일: 5,6,7 - 선택업로드 파일: 8 유전자 검사 동의서는 선택]
        };

        // 환자 의뢰를 위한 family basic 업로드 파일명
        Vue.config.globalProperties.$family_upload_filenames=[
            '서식 5. 대상자 설명문 및 동의서(희귀질환자 및 가족)',
            '서식 6. 대상자 설명문 및 동의서(희귀질환자 및 가족)- 2차적 데이터 공유 보관용',
            '서식 7. 인체유래물 동의서',
            '서식 8. 유전자 검사 동의서',
            '기타 파일'
        ];


        Vue.config.globalProperties.$family_essential_files=[true, true, true, true, false]; //  부,모: [필수 업로드 파일: 5,6,7 - 선택업로드파일: 8 유전자 검사 동의서는 선택]

        //Vue.config.globalProperties.$family_essential_files=[false, false, false, true, false]; //  부,모: [필수 업로드 파일: 5,6,7 - 선택업로드파일: 8 유전자 검사 동의서는 선택]



        Vue.config.globalProperties.$fileconstant={
            'pb_prefix':'filespb',
            'pb_types':{
                '0': 'FORM1',
                '1': 'FORM2',
                '2': 'FORM3',
                '3': 'FORM4',
                '4': 'FORM5',
                '5': 'FORM6',
                '6': 'FORM7',
                '7': 'FORM8',
                '8': 'PED',
                '9': 'ETC'
            },
            'pb_types_idx':{
                'FORM1':'0',
                'FORM2':'1',
                'FORM3':'2',
                'FORM4':'3',
                'FORM5':'4',
                'FORM6':'5',
                'FORM7':'6',
                'FORM8':'7',
                'PED':'8',
                'ETC':'9'
            },
            'fi_prefix':'filesfamily',
            'fi_types':{
                '0': 'FORM5',
                '1': 'FORM6',
                '2': 'FORM7',
                '3': 'FORM8',
                '4': 'ETC'
            },
            'fi_types_idx':{
                'FORM5':'0',
                'FORM6':'1',
                'FORM7':'2',
                'FORM8':'3',
                'ETC':'4'
            }
        }
        // Vue.config.globalProperties.$filetype_pb={
        //     '0': 'FORM1',
        //     '1': 'FORM2',
        //     '2': 'FORM3',
        //     '3': 'FORM4',
        //     '4': 'FORM5',
        //     '5': 'FORM6',
        //     '6': 'FORM7',
        //     '7': 'FORM8',
        //     '8': 'PED',
        //     '9': 'ETC'
        // }

        // Vue.config.globalProperties.$filetype_pb_idx={
        //     'FORM1':'0',
        //     'FORM2':'1',
        //     'FORM3':'2',
        //     'FORM4':'3',
        //     'FORM5':'4',
        //     'FORM6':'5',
        //     'FORM7':'6',
        //     'FORM8':'7',
        //     'PED':'8',
        //     'ETC':'9'
        // }




        // function(){
        //     return Object.keys(this.$filetype_pb).reduce((acc, k) => (acc[this.$filetype_pb[k]] = [...(acc[this.$filetype_pb[k]] || []), k], acc) , {});
        // }
       // Vue.config.globalProperties.$files_pb_prefix = 'filespb'

        // Vue.config.globalProperties.$filetype_fi = {
        //       '0': 'FORM5',
        //       '1': 'FORM6',
        //       '2': 'FORM7',
        //       '3': 'FORM8',
        //       '4': 'ETC'
        // }
        // Vue.config.globalProperties.$filetype_fi_idx={
        //     'FORM5':'0',
        //     'FORM6':'1',
        //     'FORM7':'2',
        //     'FORM8':'3',
        //     'ETC':'4'
        // }
        // =function(){
        //     return Object.keys(this.$filetype_fi).reduce((acc, k) => (acc[this.$filetype_fi[k]] = [...(acc[this.$filetype_fi[k]] || []), k], acc) , {});
        // }
        //Vue.config.globalProperties.$files_fi_prefix = 'filesfamily'
    }
}
