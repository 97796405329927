<template>
<div class="d-flex flex-column" id='#layout-schematics-frame'>
    <div class="p-12" id='#layout-schematics-topspace' style='height:40px'>

    </div>
    <div class="p-12" id='#layout-schematics-pages'>
        <div class="d-flex flex-row">
            <div class="p-2" id='#layout-leftspace' style='width:20px'>

            </div>
            <div class="p-4" id='#layout-table' style='width:500px'>
                <!-- Start: total distribution tables -->
                <div id="map-stat" v-if="this.statData" class="province-title text-left">
                    <!-- Start: 통계 테이블 -->
                    <table class="table table-hover" id="map-stat-table" style="width:100%">
                    <thead>
                    <tr class="tr-head-center">
                        <th width="5%">지역 [<label for='chk_all'>All</label> <input type='checkbox' v-model='this.allarea' true-value='yes' false-value='no' id='chk_all' />]</th>
                        <th width="95%">통계</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row,key) in this.statData" v-bind:value="key" v-bind:key="key" class="tr-row-center" >

                      <td v-if="(this.allarea =='yes')|| (this.currentProvince && (this.currentProvince['AREA'] ===row['AREA']) )" style='vertical-align:middle' width="20%" >
                          {{row['AREA']}} ({{row['TOTAL']}})
                      </td>
                      <td v-else width="20%" style='vertical-align:middle' >
                          {{row['AREA']}} ({{row['TOTAL']}})
                      </td>
                      <td v-if="(this.allarea =='yes')|| (this.currentProvince && (this.currentProvince['AREA'] ===row['AREA']) )" style='background-color:#E6F2FF' width="80%" >
                        <table style="border-collapse: collapse"  width="100%">
                        <tr style="border-bottom: 1px solid #CCCCCC">
                          <td align='left' width="10%"> <b>연령대</b>
                          </td>
                          <td width="90%">
                            <table id="map-stat-table" style="width:100%">
                            <tr>
                              <td width="40%">
                            0-6세
                              </td>
                              <td width="60%"> {{row['연령대']['EARLYCHILDHOOD']}}
                              </td>
                            </tr>
                            <tr>
                              <td width="40%">
                                7-12세
                              </td>
                              <td width="60%">
                                {{row['연령대']['MIDDLECHILDHOOD']}}
                              </td>
                            </tr>
                            <tr>
                              <td width="40%">
                                13세 이상
                              </td>
                              <td width="60%">
                                {{row['연령대']['ADOLESCENCE']}}
                              </td>
                            </tr>
                            <tr>
                              <td width="40%">NA
                              </td>
                              <td width="60%">{{row['연령대']['NA']}}
                              </td>
                            </tr>
                            </table>
                            </td>
                        </tr>
                        <tr  style="border-bottom: 1px solid #CCCCCC">
                            <td align='left'><b>성별</b>
                            </td>
                            <td width="90%">
                            <table id="map-stat-table" style="width:100%">
                            <tr>
                              <td width="40%">
                              여성
                              </td>
                              <td width="60%"> {{row['성별']['F']}}
                              </td>
                            </tr>
                            <tr>
                              <td width="40%">
                                남성
                              </td>
                              <td width="60%">
                                {{row['성별']['M']}}
                              </td>
                            </tr>
                            <tr>
                              <td width="40%">
                              NA
                              </td>
                              <td width="60%">
                              {{row['성별']['NA']}}
                              </td>
                            </tr>
                            </table>
                            </td>
                        </tr>
                        <tr  style="border-bottom: 0px solid #CCCCCC">
                            <td align='left' width="15%"> <b>의뢰현황</b>
                            </td>
                            <td width="85%">
                            <table id="map-stat-table" style="width:100%">
                            <tr>
                              <td width="40%">의뢰완료</td>
                              <td width="60%">
                                {{row['의뢰현황']['의뢰완료']}}
                              </td>
                            </tr>
                            <tr>
                              <td width="40%">접수완료</td>
                              <td width="60%">
                            {{row['의뢰현황']['접수완료']}}
                              </td>
                            </tr>
                            <tr>
                              <td width="40%">의뢰전 </td>
                              <td width="60%">{{row['의뢰현황']['의뢰전']}}</td>
                            </tr>
                            <tr>
                              <td width="40%">검토중 </td>
                              <td width="60%"> {{row['의뢰현황']['검토중']}}</td>
                            </tr>
                            <tr>
                              <td width="40%">검사완료 </td>
                              <td width="60%"> {{row['의뢰현황']['검사완료']}}</td>
                            </tr>
                            <tr>
                              <td width="40%">반려</td>
                              <td width="60%">  {{row['의뢰현황']['반려']}} </td>
                            </tr>
                            </table>
                            </td>
                        </tr>
                        </table>
                      </td>
                      <td v-else>
                        <table class="table table-hover"  width="100%">
                        <tr>
                            <td align='left'> <b>연령대</b>
                            0-6세: {{row['연령대']['EARLYCHILDHOOD']}},
                            7-12세: {{row['연령대']['MIDDLECHILDHOOD']}},
                            13세이상: {{row['연령대']['ADOLESCENCE']}},
                            NA: {{row['연령대']['NA']}}
                            </td>
                        </tr>
                        </table>
                      </td>

                    </tr>
                    </tbody>
                    </table>
                    <!-- End: 통계 테이블 -->
                </div>
                <!-- End: total distribution tables -->
            </div>
            <div class="p-6" id='#layout-schematics'>
                <div class="d-flex flex-column" id='#layout-schematics-view' style="width:800px">
                    <!-- Start: schematics title -->
                    <div class="d-flex justify-content-center" id='#layout-schematics-title' >
                         <h5>
                        이건희 소아 희귀질환 극복사업 환자 전국 의뢰 현황 <br/>
                        </h5>

                    </div>
                    <!-- End: schematics title -->
                    <!-- Start: status bar -->
                    <div class="p-6" id='#layout-schematics-bar'>

                        <div id='map-bar-progress' align='center'   >
                            <!--div id="map-bar-progress-first" :style="{'border':'solid 1px #0066CC','border-radius': '4px','width':'70%'}" -->
                            <div id="map-bar-progress-first" style="max-width:100%;">
                                <div class="progress" style="max-width: 100%;height: 1.7em">
                                    <div class="progress-bar" v-for="(item,index) in this.colorFirstHalfHexArray"
                                    :key="index" role="progressbar" :style="{'width' : (100/this.colorFirstHalfHexArray.length)+ '%','background-color': item}" :aria-valuenow="index" aria-valuemin="0" aria-valuemax="100">
                                    <span class="center h7 " style="justify-content: center;margin-bottom: 0;color:black">
                                        {{(index*100) +' - '+((index+1)*100 )+' 명'}}
                                    </span>
                                    </div>
                                </div>
                            </div>
                            <div id="map-bar-progress-second" style="max-width:100%;" >
                                <div class="progress" style="max-width: 100%;height: 1.7em">
                                    <div class="progress-bar" v-for="(item,index) in this.colorLastHalfHexArray"
                                    :key="index" role="progressbar" :style="{'width':(100/this.colorLastHalfHexArray.length)+ '%','background-color': item}" :aria-valuenow="index" aria-valuemin="0" aria-valuemax="100">
                                    <span class="center h7 " style="justify-content: center;margin-bottom: 0;color:white" v-if="index == (this.colorLastHalfHexArray.length-1)">
                                        {{((index+this.colorLastHalfHexArray.length)*100) +' 명 이상'}}
                                    </span>
                                    <span class="center h7 " style="justify-content: center;margin-bottom: 0;color:white" v-else>
                                        {{((index+this.colorLastHalfHexArray.length)*100) +' - '+(((index+1)+this.colorLastHalfHexArray.length)*100 )+' 명'}}
                                    </span>
                                    </div>
                                </div>
                                <br />
                            </div>
                        </div>

                    </div>
                    <!-- End: status bar -->
                    <div class="d-flex flex-row" id='#layout-schematics-map-view' style="width:100%">
                        <!-- Start: map-wrapper(map) -->
                        <div id="map-wrapper" class="map-wrapper" ref="map-wrapper" style="width:60%">
                        </div>
                        <!-- End: map-wrapper(map) -->

                        <!-- Start: map-age-dist(age) distribution -->
                        <div id="map-region-dist" align ='center' style="height:14vh;width:14vw;">
                            <!--age-donut-chart :ref="agedonutchart" /-->
                            <region-bar-chart  ref="regionBarChart" @stat_loaded="barChartStatDataLoaded" />

                        </div>
                        <!-- End: map-age-dist(age) distribution -->

                    </div>

                    <!-- Pie chart graphs -->
                    <div class="p-2">
                        <div class="d-flex flex-row">

                            <div class="p-2" id='#layout-schematics-agegraph'>
                                <!-- Start: map-age-dist (seq) distribution -->
                                <div id="map-age-dist-title" align ='left'>
                                <br/>
                                <h6>
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 연령별 분표
                                </h6>
                                </div>
                                <div id="map-age-dist" align ='left' style="width:20vw" >
                                  <age-donut-chart :data="age_data_collection" />
                                </div>
                                <!--  End: map-age-dist (seq) distribution -->
                            </div>
                            <div class="p-2" id='#layout-schematics-sexgraph'>
                                <!-- Start: map-sex-dist (area) distribution -->
                                <div id="map-sex-dist-title" align ='left'>
                                    <br/>
                                    <h6>
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 성별 분포
                                    </h6>

                                </div>
                                <div id="map-sex-dist" align ='center' style="width:20vw">
                                  <sex-donut-chart :data="sex_data_collection" />
                                </div>
                                <!-- End: map-sex-dist (area) distribution -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>


<script>
import * as d3 from 'd3'

//import base_parser from '@/helper/base_parser.js'

const MAP_GEOJSON = require('./map.geo.json');

import donutchart from './DonutChart.vue'
import RegionBarChart from './RegionBarChart.vue'


export default {
  components: {
    'age-donut-chart': donutchart,
    'sex-donut-chart': donutchart,
    'region-bar-chart':RegionBarChart
  },
  props: {
  },

  data() {
    return {
      province: undefined,            // 마우스가 지역구 위에 있을 때 정보
      currentProvince: undefined,     // 지역구를 클릭했을 때 정보
      allarea:'no',// check all area
      statData : undefined,             // stat
      colorFirstHalfHexArray:[],
      colorLastHalfHexArray:[],

      chartData:{},


  //---------------
    }
  },
  async created() {

  },
  async mounted() {
    this.summarize();
    this.drawMap();
  },
  computed:{
    // age-donut-chart를 렌더링하기 위해서, stat related data를 구체화함
    age_data_collection() {
      const statData = this.getStatData();
      //console.log("statData in age_data_collection() " + JSON.stringify(statData))

      var datasets = {label:['연령분포(명)'],backgroundColor:["#CCE6FF","#66B3FF","#3399FF","#888888"],data:[]}
      var age_data ={
        labels: ['0-6세','7-12세','13세이상', 'NA'],
        datasets: [datasets]
      }

      if (statData == undefined){
        return age_data
      }
      // calculate registered patient number according to sex
      var numbers=[0,0,0,0];

      Object.keys(statData).forEach(key =>{
        if (key !="TOTAL" && key !="NA"){
          const properties = this.statData[key]
          //console.log("key:"+key +":"+JSON.stringify(properties))

          numbers[0] = numbers[0] + properties['연령대']['EARLYCHILDHOOD'];
          numbers[1] = numbers[1] + properties['연령대']['MIDDLECHILDHOOD'];
          numbers[2] = numbers[2] + properties['연령대']['ADOLESCENCE'];
          numbers[3] = numbers[3] + properties['연령대']['NA'];
        }
      });

      for (var v =0 ; v<4; v++){
        //const ratio = parseInt((100* numbers[v])/total)
        age_data['datasets'][0]['data'].push(numbers[v] )
      }

      //console.log("age_data:"+age_data)
      //lert ("StatView::sex_data:"+JSON.stringify(sex_data))
      return age_data
    },
    // sex-donut-chart를 렌더링하기 위해서, stat related data를 구체화함
    sex_data_collection(){
      const statData = this.getStatData();
      //console.log("statData in age_data_collection() " + JSON.stringify(statData))
      // generate sex_data_collection

      var datasets = {label:['성별분포(명)'],backgroundColor:["#B3D9FF","#0073E6","#888888"],data:[]}
      var sex_data ={
        labels: ['남자','여자', 'NA'],
        datasets: [datasets]
      }

      if (statData == undefined){
        return sex_data
      }
      // calculate registered patient number according to sex
      var numbers=[0,0,0]
      // const total = this.statData['TOTAL']['TOTAL']

      Object.keys(statData).forEach(key =>{
        if (key !="TOTAL" && key !="NA"){
          const properties = this.statData[key]
          //console.log("key:"+key +":"+JSON.stringify(properties))

          numbers[0] = numbers[0] + properties['성별']['M'];
          numbers[1] = numbers[1] + properties['성별']['F'];
          numbers[2] = numbers[2] + properties['성별']['NA'];
        }
      });

      for (var v =0 ; v<3; v++){
        //const ratio = parseInt((100* numbers[v])/total)
        sex_data['datasets'][0]['data'].push(numbers[v] )
      }

      //console.log("age_data:"+age_data)
      //alert ("StatView::sex_data:"+JSON.stringify(sex_data))
      return sex_data
    }

  },
  methods: {

    // RegionBarChart callback function for emit 'stat_loaded' function
    barChartStatDataLoaded(){
      // alert('StatView:barChartStatDataLoaded()'+JSON.stringify(value))
      this.statData = this.$refs['regionBarChart'].getStatData();
      this.summarize()
    },
    // return this.statData
    getStatData(){
      if (this.statData == undefined ){
        return undefined
      }
      return this.statData
    },
    // summarize data received from child component
    summarize(){
      // console.log("summarize()")
      // alert("StatView::summarize()")
      this.colorFirstHalfHexArray = this.$refs['regionBarChart'].getTotalColorFirstHalfHexArray()
      this.colorLastHalfHexArray= this.$refs['regionBarChart'].getTotalColorLastHalfHexArray()

    },
    provinceColor(code) {
      return this.$refs['regionBarChart'].provinceColor(code)
    },

    // 지역 정보 열기 => province, currentProvince 둘다 제어
    openInfo(province) {

      //console.log(province);

      var sel_province = undefined

      if (province){
        // province.candidate = this.findCandidate(province.code);
        // province.final = this.statData[province.code];
        sel_province = this.findCandidate(province.code);

      }
      this.currentProvince = sel_province;
    },
    // 지역구 정보 닫기 //currentProvince 만 제어
    closeInfo() {
      this.currentProvince = undefined;
    },
    // 선택된 지역 => province 만 제어
    selectProvince(province) {
      var sel_province = undefined
      if (province){
        sel_province = this.findCandidate(province.code)

      }
      this.province = sel_province

      // if(province) {
      //   // console.log(province);
      //   // console.log(this.findCandidate(province.SGG_Code));
      //   province= this.findCandidate(province.code);
      // }
      // this.province = province;
    },
    findCandidate(code) {
      return this.statData[code];
    },
    drawMap() {
      // 지도정보
      const geojson = MAP_GEOJSON;
      // 지도의 중심점 찾기
      //var center = d3.geoCentroid(geojson);
      //console.log("center:"+JSON.stringify(center))

      let centered = undefined;

      // 현재의 브라우저의 크기 계산
      const divWidth = document.getElementById("map-wrapper").clientWidth;
      //const divWidth = this.$refs['map-wrapper'].clientWidth;
      const width = (divWidth < 600) ? divWidth * 0.9 : 600;
      const height = width * 1;

      // 지도를 그리기 위한 svg 생성
      const svg = d3
        //.select('.d3')
        .select('.map-wrapper')
        .append('svg')
        .attr('width', width)
        .attr('height', height);

      // 배경 그리기
      const background = svg.append('rect')
        .attr('class', 'background')
        .attr('width', width)
        .attr('height', height)

      // 지도가 그려지는 그래픽 노드(g) 생성
      const g = svg.append('g');
      // const effectLayer = g.append('g').classed('effect-layer', true);
      // 지도가 그려지는 그래픽 노드
      const mapLayer = g.append('g').classed('map-layer', true);
      // 아이콘이 그려지는 그래픽 노드
      //const iconsLayer = g.append('g').classed('icons-layer', true);

      // 지도의 출력 방법을 선택(메로카토르)
      // let projection = d3.geoMercator()
      //   .scale(1)
      //   .translate([0, 0]);
      let projection = d3.geoMercator().scale(1).translate([0,0])

      // svg 그림의 크기에 따라 출력될 지도의 크기를 다시 계산
      //const path = d3.geoPath().projection(projection);
      const path = d3.geoPath().projection(projection)

      const bounds = path.bounds(geojson);
      const widthScale = (bounds[1][0] - bounds[0][0]) / width;
      const heightScale = (bounds[1][1] - bounds[0][1]) / height;
      const scale = 0.95 / Math.max(widthScale, heightScale);
      const xoffset = width/2 - scale * (bounds[1][0] + bounds[0][0]) /2 + 0;
      const yoffset = height/2 - scale * (bounds[1][1] + bounds[0][1])/2 + 0;
      const offset = [xoffset, yoffset];
      projection.scale(scale).translate(offset);

      const color = d3.scaleLinear()
        .domain([1, 20])
        .clamp(true)
        // .range(['#08304b', '#08304b']);
        .range(['#FFFFFF', '#FFFFFF']); // background

      const _this = this;
      // Get province color
      function fillFn(d){
        // console.log(d, nameLength(d));
        // console.log(d.properties);


        const pcolor = _this.provinceColor(d.properties.code);
        if(pcolor) {
          return pcolor;
        }

        return color(nameLength(d));

      }

      // currentProvince 제어
      function clicked(d,i) {

        // console.log("clicked d:"+JSON.stringify(d))
        // console.log("clicked sssi"+JSON.stringify(i))
        // console.log("clicked i[properties][code]"+JSON.stringify(i.properties.code))


        var x, y, k;
        if (d && centered !== d && i !=undefined) {
          x = width / 2;
          y = height / 2;
          k = 1;
          centered = d;
          _this.openInfo(i.properties);
        } else {
          x = width / 2;
          y = height / 2;
          k = 1;
          centered = null;
          _this.closeInfo();
        }
        //console.log("clicked: currentProvince:"+JSON.stringify(_this.currentProvince))

        // Highlight the clicked province
        mapLayer.selectAll('path')
          .style('fill', function(d){

            if (centered && d===centered){
              return '#D5708B'
            }
            else{
              return fillFn(d)
            }
            //return centered && d===centered ? '#D5708B' : fillFn(d);
        });

        // Zoom
        g.transition()
          .duration(750)
          .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')scale(' + k + ')translate(' + -x + ',' + -y + ')');

      }

      //province 만 제어
      // eslint-disable-next-line no-unused-vars
      function mouseout(d){
        _this.selectProvince(undefined);
        // Reset province color
        mapLayer.selectAll('path')
          .style('fill', (d) => {
            if (centered && d===centered){
              //alert('d.properties:'+JSON.stringify(d.properties))
              return '#D5708B'
            }
            else{

              return fillFn(d)
            }

           // return centered && d===centered ? '#D5708B' : fillFn(d);

          });
        //console.log("mouseout: province:"+JSON.stringify(_this.province))
      }
      // privince 만 제어
      function mouseover(d,i){
        // Highlight hovered province
        d3.select(this).style('fill', '#1483ce');

        if(d) {
          //console.log(i.properties);
          _this.selectProvince(i.properties);
        }
        //console.log("mouseover: province:"+JSON.stringify(_this.province))
      }

      // Get province name length
      function nameLength(d){
        const n = nameFn(d);
        return n ? n.length : 0;
      }

      // Get province name
      function nameFn(d){
        return d && d.properties ? d.properties.name : null;
      }


      // Add background
      background
        .on('click', clicked);

      // 지도 그리기
      mapLayer
        .selectAll('path')
        .data(geojson.features)
        .enter().append('path')
        .attr('d', path)
        .attr('vector-effect', 'non-scaling-stroke')
        .style('fill', fillFn)
        .on('mouseover', mouseover)

        .on('mouseout', mouseout)
        .on('click', clicked);

    },
    digit(val){
      return val
    }
  }
}

</script>

<style lang="scss">
.map-wrapper {
  position:relative;
  text-align: center;

  // svg {
  //   border: 1px solid #aaaaaa;
  // }

  // .yellow
  // {
  //   background-color: yellow;
  // }
  // .gray
  // {
  //   background-color: gray;
  // }

  .province-title {
    position: absolute;
    top: 50px;
    left: 40px;
    width: 200px;
    color: #D5708B;
    z-index: 100;
    pointer-events: none;
    font-size: 0.9rem;
  }
  .province-info {
    // background: white;
    position: absolute;
    color: #D5708B;
    top: 50px;
    right: 60px;
    width: 600px;
    height: 500px;
    z-index: 101;
    font-size: 0.9rem;
    pointer-events: none;
  }
  .province-state {
    // background: white;
    position: absolute;
    color: #D5708B;
    bottom: 200px;
    right: 10px;
    width: 300px;
    z-index: 102;
    pointer-events: none;
  }
  .province-summary {
    // background: white;
    position: absolute;
    color: #D5708B;
    bottom: 10px;
    right: 10px;
    width: 300px;
    z-index: 103;
    pointer-events: none;
  }
  .province-state > ul, .province-summary > ul {
    list-style: none;
  }
  .background {
    /* fill: #021019; */
    fill: transparent;
    pointer-events: all;
  }
  .map-layer {
    fill: #08304b;
    stroke: #021019;
    stroke-width: 1px;
  }
}
</style>
