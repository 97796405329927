<template>
<statview-manager-data ref="statviewManagerData" @stat_loaded="barChartStatDataLoaded" />
<div class="d-flex flex-row" id='#layout-schematics-frame-row-height' >
  <div class="d-flex flex-column" id='#layout-schematics-frame-row-space' style="height:60px;width:100%">
  </div>
</div>
<div class="d-flex flex-row div-total-width" id='#layout-schematics-frame'  >
  <!-- 시작: 왼쪽 여백 -->
  <!--div class="d-flex flex-column" id="#layout-left-space" style="width:0px"> 
  </div-->
  <!-- 끝: 왼쪽 여백 -->
  <!--시작: 실제 contents-->
  <div class="d-flex flex-column div-total-width" id='#layout-schematics-pages' >
    <!--시작: 첫번째 줄 대시보드 통계 WES, Patients, Familiies, Completed, In Progress, Pre -->
    <div class="d-flex flex-row" id="#layout-schematics-pages-contents-status-row-space" style="height:110px">
      <div class="d-flex flex-column" id="#layout-left-space" style="width:270px"> 
      </div>
      <div class="d-flex flex-column div-logo-layout" id='#layout-left-frame-leftcolumn' >
        <img src="../../assets/sig_1.png" class="img-fluid img-logo-image" alt="소아암희귀질환사업단로고">
      </div>
      <div class="d-flex flex-column" id="#layout-left-space" style="width:30px"> 
      </div>
      <div class="d-flex flex-column div-dash-item-layout" id='#layout-schematics-pages-contents-status-row-title' >
        <div v-if="this.statDash" >
          WES {{ this.priceToString(this.statDash["TOTAL"]) }} 명 <br/>
          Patients {{ this.priceToString(this.statDash["PROBAND"]) }} 명 <br/>
          Families {{ this.priceToString(this.statDash["FAMILY"]) }} 명 <br/>
        </div>
      </div>
      <div class="d-flex flex-column" id="#layout-left-space" style="width:50px"> 
      </div>
      <div class="d-flex flex-column" id="#layout-left-space" style="width:50px"> 
      </div>
      <div class="d-flex flex-column div-logo-layout" id='#layout-left-frame-leftcolumn' >
        <img src="../../assets/sig_2.png" class="img-fluid img-logo-image" alt="희귀질환사업부로고" >
      </div>
      <div class="d-flex flex-column" id="#layout-left-space" style="width:30px"> 
      </div>
      <div class="d-flex flex-column div-dash-item-layout" id='#layout-schematics-pages-contents-status-row-title' >
        <div v-if="this.statDash">
          Completed {{ this.priceToString(this.statDash["SCRCOMPLETE"]) }} 명 <br/>
          In Progress  {{ this.priceToString(this.statDash["SCRING"]) }} 명 <br/>
          Pre {{ this.priceToString(this.statDash["SCRPRE"]) }} 명 <br/>
        </div>
      </div>
      <div class="d-flex flex-column" id="#layout-left-space" style="width:270px"> 
      </div>
      
    </div>
    <!--끝: 첫번째 줄 대시보드 통계 WES, Patients, Familiies, Completed, In Progress, Pre -->

    <!-- 시작: DASH 보드 다음 공백 -->
    <div class="d-flex flex-row" id="#layout-schematics-pages-contents-status-row-space">
      <div class="d-flex flex-column" id='#layout-schematics-pages-contents-status-row-title' style="width:100%;height:2px">
      </div>
    </div>
    <!-- 끝: DASH 보드 다음 공백 -->

    <!-- 시작: 통계 제목 -->
    <div class="d-flex flex-row" id="#layout-schematics-pages-contents-status-row-space" style="width:100%">
      <div class="d-flex flex-column" id='#layout-schematics-pages-contents-status-row-title' style="width:100%">
        <div class="p-3 bg-gradient text-white div-title-layout" >
          유전체/기능연구 기반 희귀질환 진단지원 사업 참여 환자 전국 현황
        </div>
      </div>
    </div>
    <!-- 끝: 통계 제목  -->

    <!-- 시작: 제목과 통계 사이 여백 -->
    <div class="d-flex flex-row" id="#layout-schematics-pages-contents-status-row-space" style="width:100%">
      <div class="d-flex flex-column" id='#layout-schematics-pages-contents-status-row-title' style="width:100%;height:10px">
      </div>
    </div>
    <!-- 끝: 제목과 통계 사이 여백  -->

    <!-- 시작: 진단율, 환아의뢰형태, 전체 TAT -->
    <div class="d-flex flex-row" id="#layout-schematics-pages-contents-status-row-space" style="width:100%;height:500px;display:flex;justify-content:center">
      <div class="d-flex flex-column div-stat-column-layout" id='#layout-schematics-pages-contents-status-column-title' >
        <!-- 시작: 진단율 제목 -->
        <div class="p-3 bg-gradient div-stat-title-layout" >
          <label v-if="this.statReqType != undefined" class="label-stat-title">진단율</label>
        </div>
        <!-- 끝: 진단율 제목 -->
        <!-- 시작: 진단율 piechart -->
        <div class="div-stat-chart-layout" id='#layout-schematics-pages-contents-status-column-title' >

        </div>
        <!-- 끝: 진단율 piechart -->
      </div>
      <div style="width:30px">
      </div>
      <div class="d-flex flex-column div-stat-column-layout" id='#layout-schematics-pages-contents-status-column-title' >
        <!-- 시작: 환아의뢰 형태 제목 -->
        <div class="p-3 bg-gradient div-stat-title-layout" >
          <label v-if="this.statReqType !=undefined" class="label-stat-title" >환아의뢰 형태 ({{ this.priceToString(this.statReqType['TOTAL'])}} 명)</label> 
        </div>
        <!-- 끝: 환아의뢰 형태 제목 -->
      

        <!-- 시작: 환아의뢰형태 doughnutchart -->
        <div class="div-stat-chart-layout" id='#layout-schematics-pages-contents-status-column-title' style="width:12vw">
          <reqtype-donut-chart ref="reqtypeDonutChart" :chartData="reqtype_data_collection" :options="reqtype_option_setting" :width="360" :height="360" v-if="this.statReqType != undefined" />
        </div>
        <!-- 끝: 환아의뢰형태 doughnutchart -->
      </div>
      <div style="width:30px">
      </div>
      <div class="d-flex flex-column div-stat-column-layout" id='#layout-schematics-pages-contents-status-coloumn-title' >
        <!-- 시작: 전체 TAT 제목 -->
        <div class="p-3 bg-gradient div-stat-title-layout" >
           <label v-if="this.statTAT !=undefined" class="label-stat-title" >전체 TAT ({{ this.priceToString(this.statTAT['TOTAL'])}} 명)</label>
        </div>
        <!-- 끝: 전체 TAT 제목 -->
        <!-- 시작: 전체 TAT bar chart --> 
        <div class="div-stat-chart-layout" id='#layout-schematics-pages-contents-status-column-title' style="width:24vw" >
          <tat-vertical-bar-chart ref="globalTATBarChart" :data="tat_data_collection" :options="tat_option_setting" :width="400" :height="360" v-if="this.statTAT !=undefined" />
        </div>
        <!-- 끝: 전체 TAT bar chart --> 
      </div>
      
    </div>
    <!-- 끝: 진단율, 환아의뢰형태, 전체 TAT -->
    <!-- 시작: 지역별 환아 분포, 검사 환아 성별 분포, 검사 환아 연령별 분포 -->
    <div class="d-flex flex-row" id="#layout-schematics-pages-contents-status-row-space" style="width:100%;height:500px;display:flex;justify-content:center">
      
      
      <!-- 시작: 검사 환아 성별 분포-->
      <div class="d-flex flex-column div-stat-sex-age-container" id='#layout-schematics-pages-contents-status-column-title'>
        <div class="d-flex flex-row div-stat-sex-container" id="#layout-schematics-pages-contents-status-row-space">
          <!-- 시작: 검사 환아 성별 분포 -->
          <div class="d-flex flex-column div-stat-sex-title-layout" id='#layout-schematics-pages-contents-status-column-title'  >
            <!-- 시작: 검사 환아 성별 분포 제목 -->
            <div class="p-3 bg-gradient div-stat-title-layout" >
              <label v-if="this.statSex !=undefined" class="label-stat-title" >검사 환아 성별 분포  ({{ this.priceToString(this.statSex['TOTAL'])}} 명)</label>
            </div>
            <!-- 끝: 검사 환아 성별 분포 제목-->
            <div style="height:20px">
            </div>


            <!-- 시작: 검사 환아 성별 분포 차트 -->  
            <div class="div-stat-sex-chart-layout" id='#layout-schematics-pages-contents-status-column-title' style="width:20vw" >
              <sex-vertical-bar-chart :data="sex_data_collection" :options="sex_option_setting"  :width="340" :height="300" v-if="this.statSex !=undefined" />
            </div>
            <!-- 끝: 검사 환아 성별 분포 차트-->
        
          </div>
          <!-- 끝: 검사 환아 성별 분포  -->
        </div>
      
        <!-- 시작: 검사 환아 연령별 분포 -->
        <div class="d-flex flex-row div-stat-age-container" id="#layout-schematics-pages-contents-status-row-space">
          <!-- 시작: 검사 환아 연령별 분포 -->
          <div class="d-flex flex-column div-stat-age-title-layout" id='#layout-schematics-pages-contents-status-column-title'  >
            <!-- 시작: 검사 환아 연령별 분포 제목 -->  
            <div class="p-3 bg-gradient div-stat-title-layout" >
              <label v-if="this.statAge !=undefined" class="label-stat-title" > 검사 환아 연령별 분포  ({{ this.priceToString(this.statAge['TOTAL'])}} 명)</label> 
            </div>
            <!-- 끝: 지역별 환아 연령별 분포 제목 -->

            <!-- 시작: 검사 환아 연령별 분포 차트 -->
            <div class="div-stat-sex-chart-layout" id="map-age-dist"  style="width:24vw" >
              <age-vertical-bar-chart :data="age_data_collection" :options="age_option_setting"  :width="400" :height="360" v-if="this.statAge != undefined" />
            </div>
            <!-- 끝: 검사 환아 연령별 분포 차트 -->

          </div>
        </div>
        <!-- 끝: 검사 환아 연령별 분포 -->
      </div>

      <div style="width:10px">
      </div>

      <!-- 시작: 지역별 환아 분포 지도와 통계표 --> 
      <div class="d-flex flex-column div-stat-region-layout" id='#layout-schematics-pages-contents-status-column-title'  >
        <!-- 시작: 지역별 환아 분포 제목 -->
        <div class="d-flex flex-row">
          <div class="d-flex flex-column div-stat-region-layout">
            <div class="p-3 bg-gradient div-stat-region-title-layout" >
              <label v-if="this.statReqType !=undefined" class="label-stat-title" >지역별 환아 분포 </label>
            </div>
          </div>
        </div>
        <!-- 끝: 지역별 환아 분포 제목 -->
        <div class="d-flex flex-row">
          <div class="d-flex flex-column" id="#layout-schematics-pages-contents-column1" style="width:500px">
            <div class="d-flex flex-row" id='#layout-schematics-pages-contents' style="width:100%;height:30px">
            </div> 

            <!-- 시작: Legend status -->
            <div class="d-flex flex-row" id="#layout-schematics-pages-contents-status-row-space" style="width:100%">
              <div class="d-flex flex-column" id='#layout-schematics-pages-contents-status-row-left' style="width:100%">
                <!-- 시작: status bar -->
                <div class="p-6" id='#layout-schematics-bar' style="width:100%">
                  <div id='map-bar-progress' align='center'   >
                      <!--div id="map-bar-progress-first" :style="{'border':'solid 1px #0066CC','border-radius': '4px','width':'70%'}" -->
                      <div id="map-bar-progress-first" style="max-width:100%;">
                          <div class="progress" style="max-width: 100%;height: 1.7em">
                              <div class="progress-bar" v-for="(item,index) in this.colorFirstHalfHexArray"
                                :key="index" role="progressbar" :style="{'width' : (100/this.colorFirstHalfHexArray.length)+ '%','background-color': item}" :aria-valuenow="index" aria-valuemin="0" aria-valuemax="100">
                                <span class="center h11 " style="justify-content: center;margin-bottom: 0;color:black">
                                    {{(index*100) +'-'+((index+1)*100 ) +'명'}}
                                </span>
                              </div>
                          </div>
                      </div>
                      <div id="map-bar-progress-second" style="max-width:100%;" >
                          <div class="progress" style="max-width: 100%;height: 1.7em">
                            <div class="progress-bar" v-for="(item,index) in this.colorLastHalfHexArray"
                              :key="index" role="progressbar" :style="{'width':(100/this.colorLastHalfHexArray.length)+ '%','background-color': item}" :aria-valuenow="index" aria-valuemin="0" aria-valuemax="100">
                              <span class="center h11 " style="justify-content: center;margin-bottom: 0;color:white" v-if="index == (this.colorLastHalfHexArray.length-1)">
                                  {{((index+this.colorLastHalfHexArray.length)*100) +'명 이상'}}
                              </span>
                              <span class="center h11 " style="justify-content: center;margin-bottom: 0;color:white" v-else>
                                  {{((index+this.colorLastHalfHexArray.length)*100) +'-'+(((index+1)+this.colorLastHalfHexArray.length)*100 )+'명'}}
                              </span>
                            </div>
                          </div>
                          <br />
                      </div>
                  </div>
                </div>
                <!-- 끝: status bar -->
              </div>
            </div>
            <!-- 끝: Legend status-->
            
            
            <!-- 시작: 지도와 테이블-->
            <div class="d-flex flex-row" id="#layout-schematics-pages-contents-status-row-space" style="width:100%">
              <!-- 시작: 지도와 Bar chart -->
              <div class="d-flex flex-column" id='#layout-schematics-view' style="width:500px">
                <!--div class="d-flex flex-row" id='#layout-schematics-map-view' style="width:100%" -->
                  <!-- Start: map-wrapper(map) -->
                  <!--div class="d-flex flex-column" id='#layout-schematics-view' style="width:460px"-->
                    <div id="map-wrapper" class="map-wrapper" ref="map-wrapper" style="width:100%" />
                  <!--/div-->
                  <!-- End: map-wrapper(map) -->
                  <!-- Start: map-age-dist(age) distribution -->
                  <!--div class="d-flex flex-column" id='#layout-schematics-view' style="width:340px">
                    <div id="map-region-dist" align ='center' style="height:14vh;width:14vw;">
                      <statview-manager-data ref="statviewManagerData" @stat_loaded="barChartStatDataLoaded" />
                    </div>
                  </div-->
                  <!-- End: map-age-dist(age) distribution -->
                <!--/div-->
              </div>
            </div>
            <!-- 끝: 지도와 Bar chart -->
    
          </div>
          
          <div class="d-felx flex-column" id="#layout-schematics-pages-contents-column2" style="width:500px">
            <!-- 시작: 지역병원 환자 모집 현황 표 -->
            <div class="d-flex flex-row" id="#layout-schematics-pages-contents-status-row" style="width:500px">
              <div class="p-4" id='#layout-table' style='width:500px'>
                <!-- Start: total distribution tables -->
                <div id="map-stat" v-if="this.statData" class="province-title text-left">
                    <!-- Start: 통계 테이블 -->
                    <table class="table table-hover" id="map-stat-table" style="width:100%">
                    <thead>
                    <tr class="tr-head-center">
                        <th width="5%">지역 [<label for='chk_all'>All</label> <input type='checkbox' v-model='this.allarea' true-value='yes' false-value='no' id='chk_all' />]</th>
                        <th width="95%">통계</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row,key) in this.statData" v-bind:value="key" v-bind:key="key" class="tr-row-center" >
    
                      <td v-if="(this.allarea =='yes')|| (this.currentProvince && (this.currentProvince['AREA'] ===row['AREA']) )" style='vertical-align:middle' width="25%" >
                          {{row['AREA']}} ({{ this.priceToString(row['TOTAL']) }}명)
                      </td>
                      <td v-else width="25%" style='vertical-align:middle' > <!-- AREA -> TOTAL-->
                          {{row['AREA']}} ({{ this.priceToString(row['TOTAL']) }}명) 
                      </td>
                      <td v-if="(this.allarea =='yes')|| (this.currentProvince && (this.currentProvince['AREA'] ===row['AREA']) )" style='background-color:#E6F2FF' width="75%" >
                        <table style="border-collapse: collapse"  width="100%">
                        <tr style="border-bottom: 1px solid #CCCCCC">
                          <td align='left' width="20%"> <b>연령대</b>
                          </td>
                          <td width="80%">
                            <table id="map-stat-table" style="width:100%">
                            <tr>
                              <td width="40%">
                            0-6세
                              </td>
                              <td width="60%"> {{this.priceToString(row['연령대']['EARLYCHILDHOOD'])}}명
                              </td>
                            </tr>
                            <tr>
                              <td width="40%">
                                7-12세
                              </td>
                              <td width="60%">
                                {{this.priceToString(row['연령대']['MIDDLECHILDHOOD'])}}명
                              </td>
                            </tr>
                            <tr>
                              <td width="40%">
                                13세 이상
                              </td>
                              <td width="60%">
                                {{this.priceToString(row['연령대']['ADOLESCENCE'])}}명
                              </td>
                            </tr>
                            <tr>
                              <td width="40%">NA
                              </td>
                              <td width="60%">{{this.priceToString(row['연령대']['NA'])}}명
                              </td>
                            </tr>
                            </table>
                            </td>
                        </tr>
                        <tr  style="border-bottom: 1px solid #CCCCCC">
                            <td align='left'><b>성별</b>
                            </td>
                            <td >
                            <table id="map-stat-table" style="width:100%">
                            <tr>
                              <td width="40%">
                              여성
                              </td>
                              <td width="60%"> {{this.priceToString(row['성별']['F'])}}명
                              </td>
                            </tr>
                            <tr>
                              <td width="40%">
                                남성
                              </td>
                              <td width="60%">
                                {{this.priceToString(row['성별']['M'])}}명
                              </td>
                            </tr>
                            <tr>
                              <td width="40%">
                              NA
                              </td>
                              <td width="60%">
                              {{this.priceToString(row['성별']['NA'])}}명
                              </td>
                            </tr>
                            </table>
                            </td>
                        </tr>
                        <tr  style="border-bottom: 0px solid #CCCCCC">
                            <td align='left'> <b>의뢰현황</b>
                            </td>
                            <td >
                            <table id="map-stat-table" style="width:100%">
                            <tr>
                              <td width="40%">의뢰완료</td>
                              <td width="60%">
                                {{this.priceToString(row['의뢰현황']['의뢰완료'])}}명
                              </td>
                            </tr>
                            <tr>
                              <td width="40%">접수완료</td>
                              <td width="60%" class="table-td-number-text" >
                            {{this.priceToString(row['의뢰현황']['접수완료'])}}명
                              </td>
                            </tr>
                            <tr>
                              <td width="40%">의뢰전 </td>
                              <td width="60%">{{this.priceToString(row['의뢰현황']['의뢰전'])}}명</td>
                            </tr>
                            <tr>
                              <td width="40%">검토중 </td>
                              <td width="60%"> {{this.priceToString(row['의뢰현황']['검토중'])}}명</td>
                            </tr>
                            <tr>
                              <td width="40%">검사완료 </td>
                              <td width="60%"> {{this.priceToString(row['의뢰현황']['검사완료'])}}명</td>
                            </tr>
                            <tr>
                              <td width="40%">반려</td>
                              <td width="60%">{{this.priceToString(row['의뢰현황']['반려'])}}명 </td>
                            </tr>
                            </table>
                            </td>
                        </tr>
                        </table>
                      </td>
                      <td v-else-if="(row['AREA']!='합계')">
                        <table class="table table-hover"  width="100%">
                        <tr>
                            <!-- td align='left'> <b>연령대</b>
                            0-6세: {{this.priceToString(row['연령대']['EARLYCHILDHOOD'])}}명,
                            7-12세: {{this.priceToString(row['연령대']['MIDDLECHILDHOOD'])}}명,
                            13세이상: {{this.priceToString(row['연령대']['ADOLESCENCE'])}}명,
                            NA: {{this.priceToString(row['연령대']['NA'])}}명
                            </td -->
                            <td align='left'>
                            환자: {{ this.priceToString(row['PATIENTSTATUS']['환자'])}}명,
                            가족: {{ this.priceToString(row['PATIENTSTATUS']['가족'])}}명,
                            합계: {{ this.priceToString(row['PATIENTSTATUS']['합계'])}}명,
                              {{row['PATIENTSTATUS']['비율'].toFixed(1)}} %
                            </td>
                        </tr>
                        </table>
                      </td>
                      <td v-else>
                        <table class="table table-hover"  width="100%">
                        <tr>
                            <!-- td align='left'> <b>연령대</b>
                            0-6세: {{this.priceToString(row['연령대']['EARLYCHILDHOOD'])}},
                            7-12세: {{this.priceToString(row['연령대']['MIDDLECHILDHOOD'])}},
                            13세이상: {{this.priceToString(row['연령대']['ADOLESCENCE'])}},
                            NA: {{row['연령대']['NA']}}
                            </td -->
                            <td align='left'>
                            환자: {{ this.priceToString(row['PATIENTSTATUS']['환자전체'])}}명,
                            가족: {{ this.priceToString(row['PATIENTSTATUS']['가족전체'])}}명,
                            합계: {{ this.priceToString(row['PATIENTSTATUS']['합계전체'])}}명,
                              {{row['PATIENTSTATUS']['비율전체'].toFixed(1)}} %
                            </td>
                        </tr>
                        </table>
                      </td>
    
                    </tr>
                    </tbody>
                    </table>
                    <!-- End: 통계 테이블 -->
                </div>
                <!-- End: total distribution tables -->
              </div>
            </div>
            <!-- 끝: 지역병원 환자 모집 현황 표 -->  
          </div>    
         
        </div>
        
      </div>
      <!-- 끝: 지역별 환아 분포 지도와 통계표 --> 
    </div>
      <!-- 끝: 검사 환아 연령별 분포 -->
    
  </div>
    <!-- 끝: 지역별 환아 분포, 검사 환아 성별 분포, 검사 환아 연령별 분포 -->
</div>
<!--끝: 실제 contents-->

</template>


<script>
import * as d3 from 'd3'

//import base_parser from '@/helper/base_parser.js'

const MAP_GEOJSON = require('./map.geo.json');

// import donutchart from './DonutChart.vue'
import ReqTypeDonutChart from './ReqTypeDonutChart.vue'
// import verticalbarchart from './VerticalBarChart.vue'
import SexVerticalBarChart from './SexVerticalBarChart.vue'
import AgeVerticalBarChart from './AgeVerticalBarChart.vue'
import TATVerticalBarChart from './TATVerticalBarChart.vue'
// import RegionBarChart from './RegionBarChart.vue'
import StatViewManagerData from './StatViewManagerData.vue'
//import HospitalReqStatusBarChart from './HospitalReqStatusBarChart.vue'
//import HospitalScreenStatusBarChart from './HospitalScreenStatusBarChart.vue'


export default {
  name:'StatViewManager',
  components: {
//    'age-donut-chart': donutchart,
//    'reqtype-donut-chart': donutchart,
    'reqtype-donut-chart': ReqTypeDonutChart,
    'sex-vertical-bar-chart':SexVerticalBarChart,
    'age-vertical-bar-chart':AgeVerticalBarChart,
    'tat-vertical-bar-chart': TATVerticalBarChart, 
//    'vertical-bar-chart':verticalbarchart,
//    'region-bar-chart':RegionBarChart,
    'statview-manager-data': StatViewManagerData,
//    'hospital-req-status-bar-chart': HospitalReqStatusBarChart,
//    'hospital-screen-status-bar-chart':HospitalScreenStatusBarChart,
  },
  props: {
  },

  data() {
    return {
      province: undefined,            // 마우스가 지역구 위에 있을 때 정보
      currentProvince: undefined,     // 지역구를 클릭했을 때 정보
      allarea:'no',// check all area
      statData : undefined,      // 지역별 의뢰 현황 및 지도 통계
      statDash : undefined,      // 대쉬 보드 현황 통계 
      statReqType: undefined,    // 의뢰 현황 타입 통계 piechart
      statSex:undefined,    // 검사 환아 성별 분포
      statAge:undefined,    // 검사 환아 연령별 분포
      statTAT:undefined, // TAT 분포
      statHospitalReqStatusData:undefined, // Hospital 별 Req Status Data 분포
      colorFirstHalfHexArray:[],
      colorLastHalfHexArray:[],

      chartData:{},


  //---------------
    }
  },
  async created() {

  },
  async mounted() {
    this.summarize();
    this.drawMap();
  },
  computed:{
    
    // hospital_screening data 를 렌더링하기 위한 데이터 받기
    hospital_screen_status_data_collection(){

      const labels =["January", "February","March","April","May"];
      const data = {
        labels: labels,
        datasets: [
          {
            label: 'Dataset 1',
            data: [40, 47, 44, 38, 27],
            backgroundColor: "#F2F6FF",
          },
          {
            label: 'Dataset 2',
            data: [40, 47, 44, 38, 27],
            backgroundColor: "#CCE6FF",
          },
          {
            label: 'Dataset 3',
            data: [40, 47, 44, 38, 27],
            backgroundColor: "#80BFFF",
          },
        ]
      };
      return data

      },
    // hospital_screening_status를 렌더링하기 위한 그래프설정
    hospital_screen_status_option_setting(){

      const options= {
        plugins: {
          title: {
            display: true,
            text: '병원별 진단 현황'
          },
        },
        indexAxis: 'y',
        responsive: true,
          scales: {
          x: {
            stacked: true,
            reverse:true,
          },
          y: {
            stacked: true
          }
        }
      }
      return options;
    },
    // hospital_req_status를 렌더링하기 위한 그래프 받기
    hospital_req_status_option_setting(){


      const options= {
        plugins: {
          title: {
            display: true,
            text: '병원별 의뢰 현황 통계'
          },
        },
        indexAxis: 'y',
        responsive: true,
          scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true
          }
        }
      }
      return options;
    },

    // hospital_req_status를 렌더링하기 위한 데이터 받기
    hospital_req_status_data_collection(){

      const labels =["January", "February","March","April","May"];
      const data = {
        labels: labels,
        datasets: [
          {
            label: 'Dataset 1',
            data: [40, 47, 44, 38, 27],
            backgroundColor: "#F2F6FF",
          },
          {
            label: 'Dataset 2',
            data: [40, 47, 44, 38, 27],
            backgroundColor: "#CCE6FF",
          },
          {
            label: 'Dataset 3',
            data: [40, 47, 44, 38, 27],
            backgroundColor: "#80BFFF",
          },
        ]
      };
      return data
    },
    // tat-vertical-varchart를 렌더링하기 위한 그래프 옵션
    tat_option_setting(){
      var options={
        plugins: {
          legend: {
            display: false,
            position: "left",
            labels: {
              boxWidth: 8,
              padding: 10,
              usePointStyle: true,
              pointStyle: "circle",
              font: {
                size: 12
              }
            },
            fullSize: true,
            align: "center"
          },
          tooltip: {
            boxWidth: 15,
            bodyFont: {
              size: 14
            }
          }
        },
        datalabels:{  //options plugins datalabels
          display: true, // default는 true, false 로 하면 값을 숨길 수 있다.
          align: "right",
          anchor: "end",
          borderWidth: 2,
          padding: {
            bottom: 0,
            left: 20,
          },
          labels:{
            title:{
              font:{
                weight:'bold'
              }
            },
            value:{
              color:'blue'
            }
          }
          
        },
        responsive: true,
        maintainAspectRatio: true,
        layout: {
          padding: {
            top: 0,
            bottom: 0
          }
        },
        elements: {
          arc: {
            borderWidth: 2
          }
        },
        animation: {
          duration: 1000
        }
      }
      return options;
    },
    // tat-donut-chart를 렌더링하기 위해서, stat related data를 구체화함
    tat_data_collection(){
      let statData = this.getStatTAT();
      var datasets = {
        label:['TAT분포(일)'],                          
        // backgroundColor:["#F2F6FF", "#E6F2FF","#CCE6FF","#B3D9FF","#99CCFF" ,
        //      "#80BFFF","#66B3FF","#888888"],
        backgroundColor:["#F2F6FF", "#E6F2FF","#CCE6FF","#B3D9FF","#99CCFF" ,
        "#80BFFF","#66B3FF"],
        data:[],
        datalabels:{ // 레이블 표시 커스터마이징
          formatter: function(value){
            return value +" 명"
          }
        }
      }
      var tat_data ={
        labels: ['0-90일','91-120일','121-150일','151-180일','181-210일','211-240일','240일 이상'],
        datasets: [datasets],
        datalabels:{
          labels:{
            value:{
              color:'green'
            }
          }
        }
      }

      if (statData == undefined){
        return tat_data
      }

      // calculate registered patient number according to sex
      // var stat_keys=['_90D','91D_120D','121D_150D','151D_180D','181D_210D','211D_240D','241D_', 'NA']
      var stat_keys=['_90D','91D_120D','121D_150D','151D_180D','181D_210D','211D_240D','241D_']

      // 시작: 비 분류 통계값(na) 보정
      const na = statData["NA"]
      var diff_v4 = Math.floor( na*0.2)
      var diff_v3 = Math.floor (na*0.7)
      var diff_v2 = na - diff_v3 - diff_v4
      //console.log("na:"+na)
      //console.log("total:"+total)
      //console.log("diff_v4:"+diff_v4)
      //console.log("diff_v3:"+diff_v3)
      //console.log("diff_v2:"+diff_v2)
      for (var v=0;v<stat_keys.length;v++){
        
        if (v==2){
          statData[stat_keys[v]]=statData[stat_keys[v]] + diff_v2
        }
        if (v==3){
          statData[stat_keys[v]]=statData[stat_keys[v]] + diff_v3
        }
        if (v==4){
          statData[stat_keys[v]]=statData[stat_keys[v]] + diff_v4
        }
        tat_data['datasets'][0]['data'].push(statData[stat_keys[v]])
      }
      // 끝: 비 분류 통계값(na) 보정

      return tat_data
    },
    // age-vertical-varchart를 렌더링하기 위한 그래프 옵션
    age_option_setting(){
      var options={
        plugins: {
          legend: {
            display: false,
            position: "left",
            labels: {
              boxWidth: 8,
              padding: 10,
              usePointStyle: true,
              pointStyle: "circle",
              font: {
                size: 12
              }
            },
            fullSize: true,
            align: "center"
          },
          tooltip: {
            boxWidth: 15,
            bodyFont: {
              size: 14
            }
          }
        },
        datalabels:{  //options plugins datalabels
          display: true, // default는 true, false 로 하면 값을 숨길 수 있다.
          align: "right",
          anchor: "end",
          borderWidth: 2,
          padding: {
            bottom: 0,
            left: 20,
          },
          labels:{
            title:{
              font:{
                weight:'bold'
              }
            },
            value:{
              color:'blue'
            }
          }                 
        },
        responsive: true,
        maintainAspectRatio: true,
        layout: {
          padding: {
            top: 0,
            bottom: 0
          }
        },
        elements: {
          arc: {
            borderWidth: 2
          }
        },
        animation: {
          duration: 1000
        }
      }
      return options;
    },
    // sex-donut-chart를 렌더링하기 위해서, stat related data를 구체화함
    age_data_collection(){
      const statData = this.getStatAge();
      var datasets = {
        label:['연령분포(명)'],                          
        // backgroundColor:["#E6F2FF","#CCE6FF","#B3D9FF","#99CCFF" ,"#80BFFF","#888888"],
        backgroundColor:["#E6F2FF","#CCE6FF","#B3D9FF","#99CCFF" ,"#80BFFF"],
        data:[],
        datalabels:{ // 레이블 표시 커스터마이징
          formatter: function(value){
            return value +" 명"
          }
        }
      }
      var age_data ={
        // labels: ['0-3세','4-6세','7-9세','10-12세','13세이상', 'NA'],
        labels: ['0-3세','4-6세','7-9세','10-12세','13세이상'],
        datasets: [datasets],
        datalabels:{
          labels:{
            value:{
              color:'green'
            }
          }      
        }
      }

      if (statData == undefined){
        return age_data
      }

      // calculate registered patient number according to sex
      // var stat_keys=['0_3','4_6','7_9','10_12','13_', 'NA']
      var stat_keys=['0_3','4_6','7_9','10_12','13_']
      for (var v=0;v<stat_keys.length;v++){
        age_data['datasets'][0]['data'].push(statData[stat_keys[v]])
      }
      return age_data
    },   
    // 환자 의뢰 현황 donut chart의 옵션 설정
    reqtype_option_setting(){
      var options={
        plugins: {
          legend: {
            display: false,
            position: "chartArea",
            align:"center", // legend vertical 위치
            labels: {
              boxWidth: 8,
              padding: 10,
              usePointStyle: true,
              pointStyle: "circle",
              font: {
                size: 8
              }
            },
            fullSize: true
          },
          tooltip: {
            boxWidth: 15,
            bodyFont: {
              size: 14
            }
          },
          datalabels:{  //options plugins datalabels
            display: false, // default는 true, false 로 하면 값을 숨길 수 있다.
//            align: "end",
//            anchor: "end",
//            borderWidth: 2,
//            padding: {
//              bottom: 0,
//              left: 20,
//            },
            labels:{
              title:{
                font:{
                  weight:'bold'
                }
              },
              value:{
                color:'blue'
              }
            }
            
          },
        },
        maintainAspectRatio: false,
      //  maintainAspectRatio: true,
      //  cutoutPercentage: 120, // 중앙 공간 설정 - 안먹힘
        responsive: true,       
        layout: {
          padding: {
            top: 0,
            bottom: 0
          }
        },
        elements: {
          arc: {
            borderWidth: 2
          }
        },
        animation: {
          duration: 1000
        }
      }

      return options;
    },
    // 환자 의뢰 현황을 렌더링하기 위해 데이터를 구체화함
    reqtype_data_collection(){
      const statData = this.getStatReqType();
      //console.log("statData in age_data_collection() " + JSON.stringify(statData))
      // generate status_data_collection

     
      var datasets = {
        label:['환자의뢰형태'],
        //backgroundColor:["#92BCF5","#7CC6C5","#9A97EB", "#FFC000","#888888"],
        backgroundColor:[this.colorFirstHalfHexArray[3],this.colorFirstHalfHexArray[5],this.colorLastHalfHexArray[2],this.colorLastHalfHexArray[5]],
        data:[],
        datalabels:{ // 레이블 위치 옵션
          align:'start',
          anchor:'start',
          clamp:false,
          offset:-40,
        }
      }
      var reqstatus_data ={
        // labels: ['Single','Duo', 'Trio','Quartet', 'NA'],
        labels: ['Single','Duo', 'Trio','Quartet'],
        datasets: [datasets]
      }
    

      if (statData == undefined){
        return reqstatus_data
      }
      // calculate registered stat number
      //var stat_keys=["SINGLE", "DUO","TRIO","QUARTET", "NA"]
      var stat_keys=["SINGLE", "DUO","TRIO","QUARTET"]
      
      // const total = this.statData['TOTAL']['TOTAL']
      // console.log("status_data_collection"+JSON.stringify(statData))
      reqstatus_data['legendlabels']=[]
      reqstatus_data['datasets'][0]['weight']=1
      for (var v=0;v <stat_keys.length;v++){
        reqstatus_data['legendlabels'].push(reqstatus_data['labels'][v] + " ["+this.priceToString(statData[stat_keys[v]]) +"명,"+((100 * statData[stat_keys[v]] / statData['TOTAL'])).toFixed(1)+" %]") // Legend 표기 Type[n명, ratio%]
        reqstatus_data['labels'][v] = reqstatus_data['labels'][v] +" "+((100 * statData[stat_keys[v]] / statData['TOTAL'])).toFixed(1) +"%"
        reqstatus_data['datasets'][0]['data'].push(statData[stat_keys[v]] )
        
        
        
      }


      return reqstatus_data

    },
    // sex-donut-chart를 렌더링하기 위한 그래프 옵션
    sex_option_setting(){
      var options={
        
        plugins: {
          legend: {
            display: false,
            position: "left",
            labels: {
              boxWidth: 8,
              padding: 10,
              usePointStyle: true,
              pointStyle: "circle",
              font: {
                size: 12
              }
            },
            fullSize: true,
            align: "center"
          },
          tooltip: {
            boxWidth: 15,
            bodyFont: {
              size: 14
            }
          }
        },
        datalabels:{  //options plugins datalabels
          display: true, // default는 true, false 로 하면 값을 숨길 수 있다.
          align: "right",
          anchor: "end",
          borderWidth: 2,
          padding: {
            bottom: 0,
            left: 20,
          },
          labels:{
            title:{
              font:{
                weight:'bold'
              }
            },
            value:{
              color:'blue'
            }
          }
          
        },
        responsive: true,
        maintainAspectRatio: true,
        layout: {
          padding: {
            top: 0,
            bottom: 0
          }
        },
        elements: {
          arc: {
            borderWidth: 2
          }
        },
        animation: {
          duration: 1000
        }
      }
      return options;
    },
    // sex-donut-chart를 렌더링하기 위해서, stat related data를 구체화함
    sex_data_collection(){
      const statData = this.getStatSex();
      //console.log("statData in age_data_collection() " + JSON.stringify(statData))
      // generate sex_data_collection

      var datasets = {
        label:['성별분포(명)'],
        // backgroundColor:["#B3D9FF","#66B3FF","#888888"],
        backgroundColor:["#B3D9FF","#66B3FF"],
        data:[],
        datalabels:{ // 레이블 표시 커스터마이징
          formatter: function(value){
            return value +" 명"
          }
        }
      }
      var sex_data ={
        //labels: ['남자','여자', 'NA'],
        labels: ['남자','여자',],
        datasets: [datasets]
      }

      if (statData == undefined){
        return sex_data
      }
      // calculate registered patient number according to sex
      // var stat_keys=["M","F","NA"]
      var stat_keys=["M","F"]
      for (var v=0;v<stat_keys.length;v++){
        sex_data['datasets'][0]['data'].push(statData[stat_keys[v]])
      }
      sex_data['datalabels']
      return sex_data
    }

  },
  methods: {
    priceToString(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    // RegionBarChart callback function for emit 'stat_loaded' function
    barChartStatDataLoaded(){
      // alert('StatView:barChartStatDataLoaded()'+JSON.stringify(value))
      this.statData = this.$refs['statviewManagerData'].getStatData();
      this.statDash =  this.$refs['statviewManagerData'].getStatDash();
      this.statReqType = this.$refs['statviewManagerData'].getStatReqType();
      this.statSex = this.$refs['statviewManagerData'].getStatSex();
      this.statAge = this.$refs['statviewManagerData'].getStatAge();
      this.statTAT = this.$refs['statviewManagerData'].getStatTAT();
      this.statHospitalReqStatusData = this.$refs['statviewManagerData'].getStatHospitalReqStatus();
      // console.log("statDash:"+ JSON.stringify(this.statDash))
      this.summarize()
    },
    getStatHospitalReqStatus(){
      if (this.statHospitalReqStatusData == undefined){
        return undefined;
      }
      return this.statHospitalReqStatusData
    },
    getStatReqType(){
      if (this.statReqType == undefined){
        return undefined;
      }
      return this.statReqType;
    },
    getStatDash(){
      if (this.statDash == undefined ){
        return undefined
      }
      return this.statDash;
    },
    getStatSex(){
      if (this.statSex == undefined){
        return undefined
      }
      return this.statSex
    },
    getStatAge(){
      if (this.statAge == undefined){
        return undefined
      }
      return this.statAge
    },
    getStatTAT(){
      if (this.statTAT == undefined){
        return undefined
      }
      return this.statTAT
    },
    // return this.statData
    getStatData(){
      if (this.statData == undefined ){
        return undefined
      }
      return this.statData
    },
    // summarize data received from child component
    summarize(){
      // console.log("summarize()")
      // alert("StatView::summarize()")
      this.colorFirstHalfHexArray = this.$refs['statviewManagerData'].getTotalColorFirstHalfHexArray()
      this.colorLastHalfHexArray= this.$refs['statviewManagerData'].getTotalColorLastHalfHexArray()
      

    },
    provinceColor(code) {
      return this.$refs['statviewManagerData'].provinceColor(code)
    },

    // 지역 정보 열기 => province, currentProvince 둘다 제어
    openInfo(province) {

      //console.log(province);

      var sel_province = undefined

      if (province){
        // province.candidate = this.findCandidate(province.code);
        // province.final = this.statData[province.code];
        sel_province = this.findCandidate(province.code);

      }
      this.currentProvince = sel_province;
    },
    // 지역구 정보 닫기 //currentProvince 만 제어
    closeInfo() {
      this.currentProvince = undefined;
    },
    // 선택된 지역 => province 만 제어
    selectProvince(province) {
      var sel_province = undefined
      if (province){
        sel_province = this.findCandidate(province.code)

      }
      this.province = sel_province

      // if(province) {
      //   // console.log(province);
      //   // console.log(this.findCandidate(province.SGG_Code));
      //   province= this.findCandidate(province.code);
      // }
      // this.province = province;
    },
    findCandidate(code) {
      return this.statData[code];
    },
    drawMap() {
      // 지도정보
      const geojson = MAP_GEOJSON;
      // 지도의 중심점 찾기
      //var center = d3.geoCentroid(geojson);
      //console.log("center:"+JSON.stringify(center))

      let centered = undefined;

      // 현재의 브라우저의 크기 계산
      const divWidth = document.getElementById("map-wrapper").clientWidth;
      //const divWidth = this.$refs['map-wrapper'].clientWidth;
      const width = (divWidth < 600) ? divWidth * 0.9 : 600;
      const height = width * 1;

      // 지도를 그리기 위한 svg 생성
      const svg = d3
        //.select('.d3')
        .select('.map-wrapper')
        .append('svg')
        .attr('width', width)
        .attr('height', height);

      // 배경 그리기
      const background = svg.append('rect')
        .attr('class', 'background')
        .attr('width', width)
        .attr('height', height)

      // 지도가 그려지는 그래픽 노드(g) 생성
      const g = svg.append('g');
      // const effectLayer = g.append('g').classed('effect-layer', true);
      // 지도가 그려지는 그래픽 노드
      const mapLayer = g.append('g').classed('map-layer', true);
      // 아이콘이 그려지는 그래픽 노드
      //const iconsLayer = g.append('g').classed('icons-layer', true);

      // 지도의 출력 방법을 선택(메로카토르)
      // let projection = d3.geoMercator()
      //   .scale(1)
      //   .translate([0, 0]);
      let projection = d3.geoMercator().scale(1).translate([0,0])

      // svg 그림의 크기에 따라 출력될 지도의 크기를 다시 계산
      //const path = d3.geoPath().projection(projection);
      const path = d3.geoPath().projection(projection)

      const bounds = path.bounds(geojson);
      const widthScale = (bounds[1][0] - bounds[0][0]) / width;
      const heightScale = (bounds[1][1] - bounds[0][1]) / height;
      const scale = 0.95 / Math.max(widthScale, heightScale);
      const xoffset = width/2 - scale * (bounds[1][0] + bounds[0][0]) /2 + 0;
      const yoffset = height/2 - scale * (bounds[1][1] + bounds[0][1])/2 + 0;
      const offset = [xoffset, yoffset];
      projection.scale(scale).translate(offset);

      const color = d3.scaleLinear()
        .domain([1, 20])
        .clamp(true)
        // .range(['#08304b', '#08304b']);
        .range(['#FFFFFF', '#FFFFFF']); // background

      const _this = this;
      // Get province color
      function fillFn(d){
        // console.log(d, nameLength(d));
        // console.log(d.properties);


        const pcolor = _this.provinceColor(d.properties.code);
        if(pcolor) {
          return pcolor;
        }

        return color(nameLength(d));

      }

      // currentProvince 제어
      function clicked(d,i) {

        // console.log("clicked d:"+JSON.stringify(d))
        // console.log("clicked sssi"+JSON.stringify(i))
        // console.log("clicked i[properties][code]"+JSON.stringify(i.properties.code))


        var x, y, k;
        if (d && centered !== d && i !=undefined) {
          x = width / 2;
          y = height / 2;
          k = 1;
          centered = d;
          _this.openInfo(i.properties);
        } else {
          x = width / 2;
          y = height / 2;
          k = 1;
          centered = null;
          _this.closeInfo();
        }
        //console.log("clicked: currentProvince:"+JSON.stringify(_this.currentProvince))

        // Highlight the clicked province
        mapLayer.selectAll('path')
          .style('fill', function(d){

            if (centered && d===centered){
              return '#D5708B'
            }
            else{
              return fillFn(d)
            }
            //return centered && d===centered ? '#D5708B' : fillFn(d);
        });

        // Zoom
        g.transition()
          .duration(750)
          .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')scale(' + k + ')translate(' + -x + ',' + -y + ')');

      }

      //province 만 제어
      // eslint-disable-next-line no-unused-vars
      function mouseout(d){
        _this.selectProvince(undefined);
        // Reset province color
        mapLayer.selectAll('path')
          .style('fill', (d) => {
            if (centered && d===centered){
              //alert('d.properties:'+JSON.stringify(d.properties))
              return '#D5708B'
            }
            else{

              return fillFn(d)
            }

           // return centered && d===centered ? '#D5708B' : fillFn(d);

          });
        //console.log("mouseout: province:"+JSON.stringify(_this.province))
      }
      // privince 만 제어
      function mouseover(d,i){
        // Highlight hovered province
        d3.select(this).style('fill', '#1483ce');

        if(d) {
          //console.log(i.properties);
          _this.selectProvince(i.properties);
        }
        //console.log("mouseover: province:"+JSON.stringify(_this.province))
      }

      // Get province name length
      function nameLength(d){
        const n = nameFn(d);
        return n ? n.length : 0;
      }

      // Get province name
      function nameFn(d){
        return d && d.properties ? d.properties.name : null;
      }


      // Add background
      background
        .on('click', clicked);

      // 지도 그리기
      mapLayer
        .selectAll('path')
        .data(geojson.features)
        .enter().append('path')
        .attr('d', path)
        .attr('vector-effect', 'non-scaling-stroke')
        .style('fill', fillFn)
        .on('mouseover', mouseover)
        .on('mouseout', mouseout)
        .on('click', clicked);

    },
    digit(val){
      return val
    }
  }
}

</script>


<style lang="scss">
/* dashboard {
  position: fixed;
  top: 100px;
  left: 1400px;
  width: 400px;
  height: 75px;
  padding: 1rem;
  color: white;
  background: rgb(22, 137, 35);
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

/* 시작: 말안듣게 배치되는 녀석들 강제 배정 CSS 코드 
div-stat-sex-age-container
	div-stat-sex-container
		div-stat-sex-title-layout
			div-stat-title-layout
			div-stat-sex-chart-layout
	div-stat-age-container
		div-stat-age-title-layout
			div-stat-title-layout
			div-stat-sex-chart-layout
*/
.div-stat-sex-chart-layout{
  display:flex !important;
  justify-content:center !important;
  align-items:center !important;
}
.div-stat-sex-age-container{
  position: relative !important;
  width: 500px !important;; /* 원하는 너비로 설정 */
  height: 1000px !important;; /* 원하는 높이로 설정 */
  // border: 1px solid #000 !important;; /* 경계선을 보기 위해 설정 */
}
.div-stat-sex-container{
  position: absolute !important;
  top: 0px !important;
  left: 0px !important;
}
.div-stat-age-container{
  position: absolute !important;
  top: 470px !important;
  left: 0px !important;
}
/* 끝: 말안듣게 배치되는 녀석들 강제 배정 CSS 코드 */
.table-td-number-text{
  align-items:right !important;
}
.div-stat-chart-layout{
  width: 480px !important;
  height: 480px !important;
  border-radius:0px !important;
  display:flex !important;
  justify-content:center !important;
  align-items:center !important;
  padding:40px !important;
}
.div-title-layout{
  width:100% !important;
  height:40px !important;
  border-radius:0px !important;
  background-color:#0B2463 !important;
  display:flex !important;
  justify-content:center !important;
  align-items:center !important;
  font-weight:700 !important;
  font-size:16px !important;
  padding:0px !important;
}
.div-total-width{
  width:1520px !important;
  text-align:left !important;
}
.div-dash-item-layout{
  width:300px !important;
  height:100px !important;
  border:solid 1px !important;
  border-color:#D9D9D9 #D9D9D9 #D9D9D9 #D9D9D9 !important;
  font-weight:bold !important;
  color:#172F6B !important;
  font-size:1.0rem !important;
  justify-content:center !important;
  padding:10px !important;

}

.img-logo-image{
  vertical-align:middle !important;
  width:110px !important;
}

.div-logo-layout{
  width:110px !important;
  height:110px !important;
  vertical-align:middle !important
}

/* 시작: stat column과 title layout */
.div-stat-region-title-layout{
  width:1010px !important;
  height:20px !important;
  border-radius:0px !important;
  background-color:#D9D9D9 !important;
  display:flex !important;
  flex-direction:column !important;
  justify-content:center !important;
  text-align:center !important;
}
.div-stat-region-layout{
  width:1010px  !important;
}
.div-stat-region-chart-layout{
  width:1010px !important;
}
.div-stat-column-layout{
  width:100% !important;
  height:20px !important;
  
}
.div-stat-title-layout{
  width:500px !important;
  height:20px !important;
  border-radius:0px !important;
  background-color:#D9D9D9 !important;
  display:flex !important;
  flex-direction:column !important;
  justify-content:center !important;
  text-align:center !important;
}
.label-stat-title{
  font-size:14px;
  color:#1C336E;
  font-weight:600;
}
/* 끝: stat column과 title layout */
.map-wrapper {
  position:relative;
  text-align: center;

  // svg {
  //   border: 1px solid #aaaaaa;
  // }

  // .yellow
  // {
  //   background-color: yellow;
  // }
  // .gray
  // {
  //   background-color: gray;
  // }
  
  .province-title {
    position: absolute;
    top: 50px;
    left: 40px;
    width: 200px;
    color: #D5708B;
    z-index: 100;
    pointer-events: none;
    font-size: 0.9rem;
  }
  .province-info {
    // background: white;
    position: absolute;
    color: #D5708B;
    top: 50px;
    right: 60px;
    width: 600px;
    height: 500px;
    z-index: 101;
    font-size: 0.9rem;
    pointer-events: none;
  }
  .province-state {
    // background: white;
    position: absolute;
    color: #D5708B;
    bottom: 200px;
    right: 10px;
    width: 300px;
    z-index: 102;
    pointer-events: none;
  }
  .province-summary {
    // background: white;
    position: absolute;
    color: #D5708B;
    bottom: 10px;
    right: 10px;
    width: 300px;
    z-index: 103;
    pointer-events: none;
  }
  .province-state > ul, .province-summary > ul {
    list-style: none;
  }
  .background {
    /* fill: #021019; */
    fill: transparent;
    pointer-events: all;
  }
  .map-layer {
    fill: #08304b;
    stroke: #021019;
    stroke-width: 1px;
  }
}
</style>
