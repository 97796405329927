<template>
    <div>
        <!-- 시작: 조회 -->
        <div class="row" >
            <div class="col" style="height:5px;background-color: #ffffff"></div>
        </div>

        <div class="row ">
            <div class="col  col-md-12" >
                <table border="0">
                    <colgroup>
                        <col width=500px;>
                        <col width=400px;>
                        <col width=400px;>
                    </colgroup>
                    <tr>
                        <td>
                            <label for="sel_search_status" class="col-1 label-search-right" style="width:60px"><b>진행 상태</b></label>
                            &nbsp;
                            <!--select id="sel_search_status" name="sel_search_status" v-model="this.fields.search_progress" class="form-select-sm select-search" @change="this.search_list()" style="width:160px;" -->
                            <select id="sel_search_status" name="sel_search_status" v-model="this.fields.search_progress" class="form-select-sm select-search" @change="this.search_list()" style="width:160px;" >

                                <option value="" selected>전체</option>
                                <option value="의뢰전">의뢰전</option>
                                <option value="검토중">검토중</option>
                                <option value="반려">반려</option>
                                <option value="의뢰완료">의뢰완료</option>
                                <option value="접수완료">접수완료</option>
                                <option value="검사중">검사중</option>
                                <option value="검사불가">검사불가</option>
                                <option value="검사완료">보고완료</option>
                            </select>
                        </td>
                        <td>
                            <label for="sel_search_disease_name" class="col-1 label-search-right" style="width:60px"><b>질환명</b></label>
                            &nbsp;
                            <!--input v-model="this.fields.search_disease_name" name="sel_search_disease_name" @change="this.search_list()" id="sel_search_disease_name"
                                class="form-control-sm input-search"
                                style="width:240px;"
                            /-->
                            <input v-model="this.fields.search_disease_name" name="sel_search_disease_name" id="sel_search_disease_name"
                                class="form-control-sm input-search"
                                style="width:240px;" @change="this.search_list()"
                            />
                        </td>
                        <td>
                            <label class="col-1 label-search-center" style="width:60px"><b>의뢰일</b></label>
                            &nbsp;
                            <!--input type="date" placeholder="YYYY/MM/DD" id="text_search_date_from" name="text_search_date_from" v-model="this.fields.search_request_date_start" class="input-search" @change="this.search_list()" style="width:90px;height:28px"-->
                            <!--input type="date"
                                placeholder="YYYY/MM/DD" id="text_search_date_from" name="text_search_date_from"
                                v-model="this.fields.search_request_date_start" class="input-search"  style="width:90px;height:28px"
                                :max="store.getters['constStore/getMaxDate']" @change="this.search_list()"
                            -->
                            <input type="date"
                                placeholder="YYYY/MM/DD" id="text_search_date_from" name="text_search_date_from"
                                v-model="this.fields.search_request_date_start" class="input-search"  style="width:90px;height:28px"
                                :min="store.getters['constStore/getMinDate']" 
                                :max="store.getters['constStore/getMaxDate']" v-on:keyup.enter="this.search_list()"
                                >
                            ~
                            <!--input type="date" placeholder="YYYY/MM/DD" id="text_search_date_to" name="text_search_date_to" v-model="this.fields.search_request_date_end" class=" input-search" @change="this.search_list()" style="width:90px;height:28px"-->
                            <input type="date"
                                placeholder="YYYY/MM/DD" id="text_search_date_to" name="text_search_date_to"
                                v-model="this.fields.search_request_date_end" class=" input-search" style="width:90px;height:28px"
                                :min="store.getters['constStore/getMinDate']" 
                                :max="store.getters['constStore/getMaxDate']"  v-on:keyup.enter="this.search_list()"
                                >
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label for="sel_search_hospital" class="col-1 label-search-right" style="width:60px"><b>의뢰 기관</b></label>
                            &nbsp;
                            <!--input v-model="this.fields.search_requesting_institution" list="search_requesting_institution_list_data"
                                class="form-control-sm input-search" @change="this.search_list()"
                                style="width:380px;"
                            /-->
                            <input v-model="this.fields.search_requesting_institution" list="search_requesting_institution_list_data"
                                class="form-control-sm input-search"
                                style="width:380px;" @change="this.search_list()"
                            />
                            <datalist id="search_requesting_institution_list_data">
                                <option :key="i" :data-value="d.id" v-for="(d,i) in this.search_requesting_institution_list">{{d.title}}</option>
                            </datalist>
                        </td>
                        <td>
                            <label for="sel_search_request_patient_name" class="col-1 label-search-right" style="width:60px"><b>환자명</b></label>
                            &nbsp;
                            <!--input v-model="this.fields.search_request_patient_name" name="sel_search_request_patient_name" id="sel_search_request_patient_name"
                                class="form-control-sm input-search"  @change="this.search_list()"
                                style="width:240px;"
                            /-->
                            <input v-model="this.fields.search_request_patient_name" name="sel_search_request_patient_name" id="sel_search_request_patient_name"
                                class="form-control-sm input-search"
                                style="width:240px;" @change="this.search_list()"

                            />

                        </td>

                        <td>
                            <label class="col-1 label-search-center" style="width:60px"><b>보고완료일</b></label>
                             &nbsp; 
                            <input type="date" 
                                placeholder="YYYY/MM/DD" id="text_search_screened_date_start" name="text_search_screened_date_start" 
                                v-model="this.fields.search_screened_date_start" 
                                :min="store.getters['constStore/getMinDate']" 
                                :max="store.getters['constStore/getMaxDate']" 
                                class="input-search"  v-on:keyup.enter="this.search_list()" style="width:90px;height:28px">
                            ~
                            <input type="date"
                                placeholder="YYYY/MM/DD" id="text_search_screened_date_end" name="text_search_screened_date_end"
                                v-model="this.fields.search_screened_date_end" class="input-search"  style="width:90px;height:28px"
                                :min="store.getters['constStore/getMinDate']" 
                                :max="store.getters['constStore/getMaxDate']"  v-on:keyup.enter="this.search_list()"
                            >
                            
                            

                        </td>



                        
                    </tr>
                    <tr>
                        <td>
                            

                            <label for="sel_search_request_doctor_name"  class="col-1 label-search-center" style="width:60px"><b>담당의</b></label>
                            &nbsp;
                            <!--input v-model="this.fields.search_request_patient_name" name="sel_search_request_patient_name" id="sel_search_request_patient_name"
                                class="form-control-sm input-search"  @change="this.search_list()"
                                style="width:240px;"
                            /-->
                            <input v-model="this.fields.search_request_doc_name" name="sel_search_request_doc_name" id="sel_search_request_doc_name"
                                class="form-control-sm input-search" 
                                style="width:204px;" @change="this.search_list()"
                            />

                        </td>
                        <td>
                            <!--input type="button" value="검색" @click="this.search_list()"
                                class="btn btn-primary btn-sm btn-search-action"
                                style="right:center; margin-right: 10px;"
                            /-->
                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <!-- 끝: 조회 옵션 설정 -->


        <div class="row" >
            <div class="col" style="height:30px;background-color: #ffffff">
            </div>
        </div>
        <!-- 끝: 조회 후 공백 설정 -->
    </div>

    <!-- 끝: 조회 -->
</template>
<script>
import {reactive, computed} from 'vue'

import { useStore } from 'vuex'

import api from '@/services/base/index.js'
//import {validDate} from  '@/helper/validator.js'
//import {maximum_date, minimum_date} from '@/helper/validator.js'
import moment from 'moment'
//const maximum_date = moment().format('YYYY/MM/DD');
// const minimum_date = moment('1900-01-01').format('YYYY/MM/DD');

import useVuelidate from '@vuelidate/core'
import {isProxy, toRaw} from 'vue';
/*
const validStartDate = (value, vm) => {
    return new Date(value).getTime() > new Date(vm.startDate).getTime();
};
const validEndDate = (value, vm) => {
    return new Date(value).getTime() < new Date(vm.startDate).getTime();
};

const isAfterDate = (value, vm) => {
    if (value == ""){
        return true
    }
    if (vm.search_request_date_start == ""){
        return false
    }
    if (validDate(vm.search_request_date_start)){

        return new Date(value).getTime() > new Date(vm.search_request_date_start).getTime();
    }
    else{
        return true
    }
};

const isBeforeDate = (value, vm) => {
    if (value == ""){
        return true
    }
    if (vm.search_request_date_end == ""){
        return false
    }
    if (validDate(vm.search_request_date_end)){

        return new Date(value).getTime() < new Date(vm.search_request_date_end).getTime();
    }
    else{
        return true
    }
};
*/

const isValidDate = (value) => {
    
    
    if (value == ""){ // 유효한 날짜를 입력 안했거나 공백임
        return true
    }
    else{
        const maximum_date = moment().format('YYYY/MM/DD');
        const minimum_date = moment('1900-01-01').format('YYYY/MM/DD');
        var date = moment(value).format('YYYY/MM/DD');
        if (date > minimum_date && date <= maximum_date){
            return true
        }
        alert ('유효한 날짜를 입력해주세요. 입력한 날짜:'+date.toString())
        return false
    }
};

export default {
    name: 'Search',
    data() {
        let search_requesting_institution_list = reactive([]);
        let fields = reactive({
            search_progress:'', // 진행상태
            search_requesting_institution:'',// 의뢰기관
            search_disease_name:'', // 질환명
            search_request_date_start:'', // 의뢰일_시작
            search_request_date_end:'', // 의뢰일_끝
            search_request_patient_name:'',// 환자명

            search_screened_date_start:'', //결과보고일 시작
            search_screened_date_end:'', // 결과보고일 끝
            search_request_doc_name:'' // 담당의
        });

        const fields_rule = computed(() =>{
            return {
                search_progress: {},
                search_requesting_institution: {}, //, isBetween:[10,20]},
                search_disease_name:{},
                
                // search_request_date_start:{validDate, isBeforeDate},
                search_request_date_start:{isValidDate},
                search_request_date_end:{isValidDate},
                search_request_patient_name:{},

                search_screened_date_start:{isValidDate}, 
                search_screened_date_end:{isValidDate},
                search_request_doc_name:{} 
            };
        });
        // validators declareration for each validation
        const v_fields$ = useVuelidate(fields_rule, fields);


        return {
            fields,
            search_requesting_institution_list,
            v_fields$
        }
    },
    // created function
    async created(){

    },
    
    // mounted function
    async mounted() {

        // 시작: search data 를 웹브라우저 localStorage로 부터 읽어와 fields 와 동기화 한다
        const search_data = JSON.parse(localStorage.getItem('search_data'))
        
        if (search_data != null){
            if (isProxy(search_data)){
                this.fields = toRaw(search_data)
            }
            else{
                this.fields = search_data
            }
            

        }
        
        // 끝: search data 를 웹브라우저 localStorage로 부터 읽어와 fields 와 동기화 한다
        
        api.getHospitalList()
        //axios.get('/hospital/') //,axios_config)
        .then(response => {
            // this.user.hospital=null;
            this.search_requesting_institution_list = response.data.map( d => ({
                hcode: d.hcode,
                id:d.id,
                name:d.name,
                title:d.name
            }))
        }).catch(err=>{
            console.log(err)
        })

    },
    setup(){
        const store = useStore();
        // var temp_basis_info = JSON.parse(localStorage.getItem('pb_info'));
        // alert ('loaded:'+JSON.stringify(temp_basis_info));
        // this.pb_obj = temp_basis_info;

        

        return {store}
    },
    methods:{
        validate(){
            this.v_fields$.$touch()
            //console.log(JSON.stringify(this.v_fields$));
            if (this.v_fields$['$error']){

                //<input type="date"
                //                placeholder="YYYY/MM/DD" id="text_search_date_from" name="text_search_date_from"
                //                v-model="this.fields.search_request_date_start" class="input-search"  style="width:90px;height:28px"
                //                :max="store.getters['constStore/getMaxDate']" v-on:keyup.enter="this.search_list()"
                //                >
                
                //console.log("errors:"+JSON.stringify(this.v_fields$['$errors']))
                //console.log("store.getters['constStore/getMaxDate']:"+ this.store.getters['constStore/getMaxDate']+", this.fields.search_request_date_start:"+this.fields.search_request_date_start)

            }
            return !this.v_fields$['$error']


        },
        search_list(){
            //alert('Search::search_list() is called with fields:'+JSON.stringify(this.fields))
            if (this.validate()){
                //alert('fields_changed will be called')
                this.$emit('fields_changed',this.fields)
            }
        },
        get_fields(){
            return this.fields
        }

    }

}
</script>
<style scoped>
</style>
