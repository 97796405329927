<template>
  <div >
    <nav class="navbar navbar-expand-lg fixed-top"  >
      <div class="row" v-if="this.isLoggedIn()"  style="width:100%" >
        <div class="col-1">
        <a href="/home" class="nav-link">Home</a>
        </div>
        <div class="col-1">
        <a href="/request" class="nav-link">진단의뢰</a>
        </div>

        <div class="col-1" v-if="this.isManager()">
        <a href="/request_manage" class="nav-link">진단의뢰관리</a>
        </div>

        <div class="col-1" v-if="this.isManager()">
        <a href="/screen_manage_list" class="nav-link">진단검사관리</a>
        </div>

        <div class="col-1" >
        <a href="/screen_list" class="nav-link">진단검사결과</a>
        </div>

        <div class="col-1" v-if="this.isManager()">
          <a href="/statviewmanager" class="nav-link">통계관리</a>
        </div>

        <div class="col-1">
        <a href="/statview" class="nav-link" >통계</a>
        </div>

        <div class="col-4" v-if="!this.isManager()">
        </div>
        <div class="col-1" v-else>
          
        </div>
        <div class="col-3">
          <div class="disp_muted_info" style="vertical-align:middle">
          {{store.getters['userStore/getUserEmail']}} {{store.getters['userStore/getUserHospitalName']}}
          </div>
        </div>
        <div class="col-1">
          <a href="/login" class="nav-link" @click="onLogout" >Logout</a>
        </div>
      </div>

      <div class="row" style="width:100%"  v-else>
        <div class="col-1">
          <a href="/home" class="nav-link">Home</a>
        </div>
        <div class="col-1">
          <a href="/statview" class="nav-link">통계</a>
        </div>

        <div class="col-8">
        </div>
        <div class="col-1">
            <a href="/login" class="nav-link">Login</a>
        </div>
        <div class="col-1">
          <a href="/usersignup" class="nav-link">Sign up</a>
        </div>
      </div>


    </nav>

    <table border=0 height="40px">
    <tr>
      <td height="40px">
      </td>
    </tr>
    </table>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import {mapActions} from 'vuex'
const userStore = 'userStore'

export default {
  name: 'MainHeader',
  data() {
    //let userrole = ref('')

    return {
      //userrole
    }
  },
  computed : {

  },
  mounted:function(){

  },
  methods: {
    ...mapActions(userStore,['logout']),
    isLoggedIn : function(){
      //alert('userStore:isLoggedIn()')
      //console.log('MainHeader:isLoggedIn()')

      return this.store.getters['userStore/isLogin'];
      //return this.isLogin
    },
    isManager:function(){
      //console.log('MainHeader:isManager()')
      return this.store.getters['userStore/isManager'];

    },
    onLogout (){
      //alert ('MainHeader:onLogout()');
      this.logout();

    },


  },
  setup(){
    const store = useStore();
    return {store}
  }
}
</script>

<style scoped>
@import '@/assets/base.css'
</style>
