<template>
  
  <Bar :data="data" :options="options" :width="this.width" :height="this.height"  id="tat-bar-chart" />
</template>

<script>


import { Bar } from 'vue-chartjs'

import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
//import { reactive } from 'vue'

ChartJS.register(ChartDataLabels, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'TATVerticalBarChart',
  components: { Bar },
  plugins:[ChartDataLabels],
  data(){
    return {}
  },
  props:{
    data:{
      type:Object,
      required:true
    },
    options:{
      type: Object,
      default:()=>{}
    },
    height:Number,
    width:Number
  }
}

</script>
<style scoped>

</style>