//import api from '@/services/base/index.js'

const state = {
// userInfo: null,
    loginObj:null
}

// export default new Vuex.Store({
const patientReqStore = {
    namespaced: true,
    state,
    mutations:{
        setLoginObj(state,loginObj){
            state.loginObj = loginObj
            //alert ('patientReqStore:mutations:setLoginObj with loginStatus:' +JSON.stringify(state.loginObj))
        },
    },
    getters:{
        getLoginObj(state){
            return state.loginObj
        },
    },
    actions: {
        

    }
}


export default patientReqStore;
