/**
 * for web constriant designation
 */
const state = {
    // userInfo: null,
    maxDate:'2099-12-31',
    minDate:'1900-01-01',
    baisUploadFileTypes:'image/png, image/jpeg, application/pdf,application/vnd.ms-excel, application/vnd.ms-word'

}
// export default new Vuex.Store({
const constStore = {
    namespaced: true,
    state: state,
    getters:{
        getMinDate(state){
            return state.minDate
        },
        getMaxDate(state){
            //nalert('store:getUserEmail:'+JSON.stringify(state.userInfo))
            return state.maxDate
        },
        getBasisUploadFileTypes(state){
            return state.baisUploadFileTypes
        }
    }
}
export default constStore;
