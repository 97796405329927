<template>
  <div class="chart-container">
    
    <Doughnut :data="this.chartData" :options="this.options" :width="this.width" :height="this.height" id="reqtype-doughnut-chart" />
    
    <div ref="labels" v-for="(text,index) in this.chartData.labels" :key="index" :style="this.positionStyle(index)">
      <!--font color="black"-->{{  text  }}<!--/font-->
    </div>
    <div ref='legend' class="legend-container" style="font-size:10pt;font-weight:700"/>
  </div>
</template>

<script>


import { Doughnut } from 'vue-chartjs'

import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
//import { reactive } from 'vue'

ChartJS.register(ChartDataLabels, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'ReqTypeDonutChart',
  components: { Doughnut },
  props:{
    chartData:{
      type:Object,
      required:true
    },
    options:{
      type: Object,
      default:()=>{}
    },
    height:Number,
    width:Number
  },
  plugins:[ChartDataLabels],
  data(){
    
  },
  mounted(){
    this.createCustomLegend()
   
  },
  methods: {
    createCustomLegend() {
      const legendContainer = this.$refs.legend
      const ul = document.createElement('ul')
      this.chartData.legendlabels.forEach((legendtext, index) => {
        const li = document.createElement('li')
        li.style.color = this.chartData.datasets[0].backgroundColor[index]
        li.textContent = legendtext
        ul.appendChild(li)
      })
      legendContainer.appendChild(ul)
    },
    positionStyle(index){
      const xStart = 180;
      const yStart = -18;
      let labelPositions=[
          {x:xStart, y: yStart},  // Single
          {x:xStart+60, y: yStart+16},  // Duo
          {x:xStart-20, y: yStart+380}, // Trio
          {x:xStart-100, y: yStart}, // Qurtet
          
        ]
      const position = labelPositions[index]  
      const color = this.chartData.datasets[0].backgroundColor[index]
      
      return { position:'absolute', left:position.x + "px", top:position.y+"px", color:color, 'font-size': "14px", 'font-weight':600}
    }
  }


}

</script>


<!--/script-->
<style scoped>
.lable-container{
  position: absolute;
  top: 0%;
  left: 0%;
  transform: translate(-50%, -50%);
}
.chart-container {
  position: relative;
}
.legend-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
