<template>
    <div>
        
        <table border="0">
        <tr>
            <td colspan="5">
                <font color="#444444"><h6><b>업로드된 파일</b></h6></font>
            </td>
        </tr>
        
        <tr>
            <th align="center" >파일명</th>
            <th align="center" >업로드일자</th>
            <th align="center" >관리</th>
            <th> 보고날짜 </th>
            <th> 보고현황 </th>
        </tr>
        <tr>
            <td colspan="5" height="1px"  >
                <hr size="1" width ="100%" color="#777777" />
            </td>
        </tr>
        <tr v-for="(file, idx) in this.files" :key="idx" style="padding-top: 10px;">

            <td align="left" width="40%">
                <div style="float: left;" @click.stop="" @click="this.file_download(file.file_path, file.filename)">
                {{ file.filename }} 
                
                </div>
            </td>
            <td align="left">
                {{ file.upload_date}} 
            </td>
            <td align="center">
                <!--div v-if="file.redeliver_date!=''">
                    <button href="#" class="btn btn-primary btn-sm btn-search-action" id="btn_report_rollback"
                        style="float:right; margin-right: 10px;" @click.stop ="" @click="this.report_rollback(file.id)">취소</button>
                </div>
                <div v-else -->
                <div>
                    <button href="#" class="btn btn-primary btn-sm btn-search-action" id="btn_excel_download"
                    style="float:right; margin-right: 10px;" @click="this.report_removed(file.id)" :disabled ="file.redeliver_date !=''">삭제</button>
                
            
                    <button href="#" class="btn btn-primary btn-sm btn-search-action" id="btn_report_file_delivered"
                    style="float:right; margin-right: 10px;" @click="this.report_delivered('__FILE__',file.id)" :disabled ="file.redeliver_date !=''">보고</button>
                </div>
            </td>
            <td align="center">
                <div v-if="file.redeliver_date!=''">
                    {{ file.redeliver_date }} 
                </div>
                <div v-else>
                   -
                </div>
            </td>
            <td align="center">
                <div v-if="file.redeliver_date!=''">
                    <font color="#FF0000">보고됨</font>
                </div>
                <div v-else>
                   -
                </div>
            </td>

        </tr>

        <tr>
            <td colspan="2" aligh="left">
                <input type="file" style="width:200px;height:20;font-size:14px" ref="ref_store_files" id="ref_store_files"
                            @change="this.change_file($event.target)" /> 
            </td>
            <td  aligh="left">
                <button href="#" class="btn btn-primary btn-sm btn-search-action" :disabled="this.changed_upload_file" id="ref_report_upload" ref="ref_report_upload"
                            @click="this.upload_file()"
                            style="float:right; margin-right: 10px;"> Upload </button>
            </td>
            <td  aligh="left" colspan="2">
            </td>
        </tr>
        <tr>
            <td colspan="5" height="10px">
                <hr size="1" width ="100%" color="#777777" />
            </td>
        </tr>
        
        <!-- 시작: EMR 보고 기능 -->
        <tr>
            <td colspan="2" >
            </td>
            <td align="center">     
                <button href="#" class="btn btn-primary btn-sm btn-search-action" id="btn_report_emr_delivered" :disabled="this.is_reported('__EMR__')"
                style="float:right; margin-right: 10px;" @click="this.report_delivered('__EMR__','' )">EMR 보고</button> 
                
            </td>
            <td align="center">
                <div v-if="this.is_reported('__EMR__')">
                {{ this.extract_reported_date('__EMR__') }} 
                </div>
                <div v-else>
                   -
                </div>
            </td>
            <td align="center">
                <div v-if="this.is_reported('__EMR__')"><font color="#FF0000">보고됨 </font> 
                </div>
                <div v-else>
                   미보고됨
                </div>
            </td>

        </tr>
        <!-- 끝: EMR 보고 기능 -->
        <!--tr>
            <td colspan="5" height="10px">
                <hr size="1" width ="100%" color="#777777" />
            </td>
        </tr-->
        <!-- 시작: URL 보고 기능 -->
        <!--tr>
            <td colspan="5" >
                <button href="#" class="btn btn-primary btn-sm btn-search-action" id="btn_report_url_delivered " :disabled="1==1" @click="this.report_delivered('__URL__','' )" 
                                                style="float:right; margin-right: 10px;">URL 보고</button>
            </td>
        </tr-->
        <!-- 끝: URL 보고 기능 -->
        <tr>
            <td colspan="5" height="10px">
                <hr size="1" width ="100%" color="#777777" />
            </td>
        </tr>
        
        
        
        </table>

    </div>
</template>
<script>
// import {reactive} from 'vue'

import { useStore } from 'vuex'

import api from '@/services/base/index.js'
//import { FALSE } from 'node-sass'
// import { reactive} from 'vue'

export default {
    name: 'FileUploader',
    props:['id','files','result_url','reqscreened'],
//:pat_id="row['pb_obj']['id']" :dia_info="row['pb_obj']"/>

    data() {

        
        let changed_upload_file=true;
        return {upload_files:'',changed_upload_file}
    },
    setup(){
        const store = useStore();
        // var temp_basis_info = JSON.parse(localStorage.getItem('pb_info'));
        // alert ('loaded:'+JSON.stringify(temp_basis_info));
        // this.pb_obj = temp_basis_info;


        


        return {store}
    },
    methods:{
        // /Check EMR reported or not 
        is_reported(_report_type){ 
            return this.result_url.includes(_report_type) 
        },
        extract_reported_date(_report_type){
            if (this.is_reported(_report_type)){
                var resulturl_data = this.result_url;
                let resulturl_arr = resulturl_data.split('|')
                var reported_type_idx = -1
                for (var i =0; i<resulturl_arr.length;i++){
                    var resulturl = resulturl_arr[i];
                    if (resulturl.includes(_report_type)){
                        reported_type_idx = i;
                        break;
                    }
                }
                let reqscreened_data = this.reqscreened;
                let reqscreened_arr = reqscreened_data.split('|')
                return reqscreened_arr[reported_type_idx];
            }
            return ""
            
        },
        change_file(){
            
            if (this.$refs['ref_store_files'].value != ''){
                this.changed_upload_file = false
            }
            else{
                this.changed_upload_file = true
            }

        },
        upload_file(){
            

            const upload_files = this.$refs.ref_store_files.files

            if (upload_files.length < 1){
                return false;
            }
            // reqfile information

            for (let i =0;i<upload_files.length;i++){
                upload_files[i].src = this.get_file_src(upload_files[i])
                upload_files[i].prefix = this.$fileconstant.pb_prefix
                upload_files[i].req_id = this.id // patient id
                upload_files[i].filetype='DIA'
                upload_files[i].db_id = '-'
            }
            
            // alert ("target_file.src:" + target_files.src +", target_file.prefix:"+target_files.prefix + ",target_file.req_id:"+target_files.req_id+", target_file.filetype:"+target_files.filetype)

            api.uploadFileObj(upload_files)           
            .then(response =>{
                // console.log('mounted:function after change_pb_uploadfiles (response)'+JSON.stringify(response))
                if (response['result'] == 'success'){
                    // alert ('기본 환자 정보가 수정되었습니다')
                    // DB 에 수정된 파일 정보 갱신, DB ID 설정 필요
                    
                    let fields = {
                        p_id:this.id, // patient id
                        file_id:[] // file id
                    };
                    for (let i =0;i<response['data']['message'].length;i++){
                        const updated_file_info = response['data']['message'][i]
                        fields['file_id'].push({name:updated_file_info.filename,id:updated_file_info.id, file_path:updated_file_info.file_path})
                    }

                    //this.set_CRUDENV_loading(false);
                    //this.$router.go(0);

                    
                    //this.$emit('report_uploaded',response['data'])
                
                    this.$emit('report_uploaded',fields)
        
                    return true
                }

                alert ('입력된 파일 정보 수정에 문제가 있습니다.')
                //this.set_CRUDENV_loading(false);
                return false


            }).catch(err =>{
                console.log(err)

                //this.set_CRUDENV_loading(false);
                return false
            })
            // this.$router.go(0);
        },
        // FileReader를 통해 파일을 읽어 thumbnail 영역의 src 값으로 셋팅
        async get_file_src (files) {
            return new Promise((resolve) => {
                const reader = new FileReader()
                reader.onload = async (e) => {
                    resolve(e.target.result)
                }
                reader.readAsDataURL(files)
            })
        },
        file_remove(_idx,_fidx){
            const idx = _idx; // web list index
            const db_id = _fidx;  // db id
            //alert ('web list idx:'+idx+",db_id:"+db_id);
            
            // check db_id and category
            if (this.files[idx].id != db_id || this.files[idx].category !='DIA'){
                alert ('잘못된 ID의 파일을 삭제시도했습니다..')
                return
            }
            // file 삭제 시도
            api.deleteFileObj(this.$fileconstant.pb_prefix, db_id)
            .then (response =>{

                if (response['result'] != 'success'){
                    alert ('입력된 파일 정보 수정에 문제가 있습니다.')
                    this.set_CRUDENV_loading(false);
                    return false
                }
            }).catch(err =>{
                console.log(err)
                this.set_CRUDENV_loading(false);
                return false
            })
            this.$router.go(0);
            // this.$emit('dia_deleted',idx)
            //this.list_files.splice(idx, 1);

        },
        report_removed(_file_id){
            let fields = {
                p_id:this.id, // patient id
                file_id:_file_id // file id
            };
            this.$emit('report_removed',fields)
        },
        report_delivered( _report_type, _report_content){ //_file_id){ // _file_id : db file id

            let fields = {
                p_id:this.id, // patient id
                report_type: _report_type,
                file_id:_report_content // file id
            };
            this.$emit('report_delivered',fields)
        },
        report_rollback( _file_id){ // _file_id : db file id

            let fields = {
                p_id:this.id, // patient id
                file_id:_file_id // file id
            };
            this.$emit('report_rollback',fields)
        },
        async file_download (file_path, file_name) {
            api.downloadFileObj(file_path).then(response=>{
                let blob = new Blob([response.data], { type: response.headers['content-type'] })
                file_name = decodeURI(file_name) // 파일명 디코딩 (프로젝트에 따라 사용여부 옵션)
                if (window.navigator.msSaveOrOpenBlob) { // IE 10+
                    window.navigator.msSaveOrOpenBlob(blob, file_name)
                } else { // not IE
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.target = '_self'
                    if (file_name) link.download = file_name
                    link.click()
                }
            })
        },
        
   
    },
    

}
</script>
<style scoped>
</style>