import api from '@/services/base/index.js'

export default{
  name:"base_parser",

  // // 문자열을 붙인다.
  // concat_string(a,b){
  //     return a+b
  // },
  // abbreviate(_str,_num){
  //   return _str.substr(0,_num) + '...'
  // },
  // 로컬에 저장된 token('jwt')을 기반으로 Authorization 헤더 config를 반환한다.
  getConfigWithToken(){
      const token = localStorage.getItem('jwt')
      let config = {
        headers:{
          Authorization : 'JWT ' + token
        }
      };
      return config
  },
  // backend 에서 받은 response header에서 성공 여부를 true, false로 반환한다.
  getResultFromResponse(response){
      if (response.data.result == 'fail'){
          return false;
      }
      else if (response.data.result == 'success'){
          return true;
      }
      else{
          throw new Error('response.data.result is not valid');
      }
  },

  // recursive 를 이용해 deep copy를 수행한다.
  clone(from,to) {
    //console.log('base_parser:clone')
    to = to || {};
    for (let name in from) {
      if (from[name] instanceof Object) {
        // so let's figure out obj [name] is it an object
        to[name] = from[name] instanceof Array ? [] : {}; // we let the name item of the object to be copied = array or json
        this.clone(from[name], to[name]); // and then i'm going to call the function infinitely and recursively on its own
      } else {
        to[name] = from[name]; // if it's not an object, it's just equal to ， no reference occurs.
      }
    }
    return to; // then return the copied object
  },
  // backend의 response header에서 jwt_token을 추출해서 반환함 없으면 null 반환함
  getAccessTokenFromResponse(response){

      // >> res:
        //    {"data":
        //      {"result":"success",
        //      "data":
        //         {"user":{"username":"류동성","hospital":12,"email":"bluesky4383@gmail.com","phone_number":"010-6600-9949"},
        //          "jwt_token":{"access_token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjUwMjcxODY5LCJpYXQiOjE2NTAyNzE1NjksImp0aSI6IjI0NWM5M2ZkNjBmODRiZTFiZWYyZjBmYjM4MmVjNWNhIiwidXNlcl9pZCI6Mn0.W_QaPQvClUvUhfomyP5dolFcrvP_yp-NRQPRhb2jNUU",
        //                      "refresh_token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTY1MDM1Nzk2OSwiaWF0IjoxNjUwMjcxNTY5LCJqdGkiOiJjNTJmMjhkZjcxZWI0MjMyOWEyNWEwMDJjMGU0ZGQ4NyIsInVzZXJfaWQiOjJ9.Ifpkbo0T5gUCor91w4E0BxkUWzgewOUyrgyJDkjRH_8"
        //                      }
        //          }
        //      },
        //

      // console.log("response:"+JSON.stringify(response))
      let result = this.getResultFromResponse(response);
      if (result){

          // console.log('>>getTokenFromResponse [response.data.data.jwt_token.access_token]: ' +JSON.stringify(response.data.data.jwt_token.access_token));
          return response.data.data.jwt_token.access_token;
      }
      else{
          return "";
      }
  },
  //입력 받은 _str을 _delimeter 로 구분해서 배열을 반환한다
  split_string_with_delimeter(_str,_delimeter){
    if (_str == ''){
      return [];
    }
    else{
      return _str.split(_delimeter);
    }

  },
  // 질병 코드 관련해서, backend와 통신할 수 있는 자료 구조를 구축함
  // let hpo_codes = parser.make_hpo_code_array (pb_obj.pb_diseasename_hpo,pb_obj.pb_diseasename_hpo_textnumber,"|");
  make_hpo_code_array(_str,_numbers,_delimeter){
    if (_str =='' && _numbers == ''){
      return [];
    }
    let parsed_data=[];

    const str_array = this.split_string_with_delimeter(_str,_delimeter)
    const number_array = this.split_string_with_delimeter(_numbers,_delimeter)
    const array = str_array

    let array_length = array.length
    if (str_array.length < number_array.length){
      array_length = number_array.length
    }
    for (let i=0;i<array_length;i++){
      let codes = ''
      let title = ''
      let number = ''
      if (str_array[i] != undefined && str_array[i] !=''){
        const row = str_array[i];

        const re = /\[(.*)\]/g ;
        codes = row.match(re)[0].replace('[',"").replace(']',"");
        title = row.replace(re,"");

      }
      if (number_array[i] != undefined && number_array[i] != ""){

        number = number_array[i]

      }
      // {id:'',codes:'', title:'', value:'', textnumber:''}

      parsed_data[i]={codes:codes,title:title, textnumber:number}

    }
    return parsed_data;


  },
  // 질병 코드 관련해서, backend와 통신할 수 있는 자료 구조를 구축함
  make_code_array(_str,_delimeter){
    if (_str ==''){
      return [];
    }
    const array = this.split_string_with_delimeter(_str,_delimeter)
    let parsed_data=[];
    for (let i=0;i<array.length;i++){
      const row = array[i]; // id, title로 분할 해서 데이터 배열에 push
      
      const re = /\[(.*)\]/g ;
      const codes = row.match(re)[0].replace('[',"").replace(']',"");
      const title = row.replace(re,"");
      parsed_data[i]={codes:codes,title:title}
     
    }
    return parsed_data;

  },
  // 연령을 반환한다
  get_age(fromDateString, toDateString)
  {

    var today = new Date(fromDateString);
    var birthDate = new Date(toDateString);
    var age = today.getFullYear() - birthDate.getFullYear();

    var m = today.getMonth() - birthDate.getMonth();
    
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate()))
    {
        age--;
    }
    
    return age;
  },

  async file_download (file_path, file_name) {
    api.downloadFileObj(file_path).then(response=>{
        let blob = new Blob([response.data], { type: response.headers['content-type'] })
        file_name = decodeURI(file_name) // 파일명 디코딩 (프로젝트에 따라 사용여부 옵션)
        if (window.navigator.msSaveOrOpenBlob) { // IE 10+
            window.navigator.msSaveOrOpenBlob(blob, file_name)
        } else { // not IE
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.target = '_self'
            if (file_name) link.download = file_name
            link.click()
        }
    })
},
// '검사완료' -> '보고완료' 로 변환함
convert_patient_status(_status){
  if (_status == '검사완료'){
    return '보고완료'
  }
  return _status
}

//--------------------------------
// // 안씀 - 재귀함수 호출로 formData 를 구성한다.
  // buildFormData(formData, data, parentKey) {
  //   if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
  //     Object.keys(data).forEach(key => {
  //       this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
  //     });
  //   } else {
  //     const value = data == null ? '' : data;

  //     formData.append(parentKey, value);
  //   }
  // },
  // // 안씀 - json data 를 formData 로 변환한다
  // // const formData = new FormData();
  // // jsontToFormData(formData, json)
  // jsonToFormData(formData, jsondata) {
  //   this.buildFormData(formData, jsondata);
  //   return formData;
  // },
// 안씀 -  random string 을 생성해서 반환한다.
// addTimeStampAtFront(_value){
//   //alert ('addTimeStampAtFront with _value:'+_value)
//   const timestamp = moment().format('YYYYMMDDHHMMSS')

//   let returnValue = timestamp +"_"+_value

//   if (_value == ""){
//     returnValue = ""
//   }
//   //alert ('addTimeStampAtFront returnValue:' + returnValue )
//   return returnValue
// },
}

