<template>
  <Doughnut :data="chartData"  />
</template>
<script>
import { Doughnut } from 'vue-chartjs'
// import {ChartJSPluginDatalabels} from "@/assets/chartjs-plugin-datalabels.esm.js"
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";


import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, ArcElement,CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)

// ChartJS.defaults.set('plugins.datalabels',{})

export default {
  name: 'donutchart',
  components: { Doughnut },
  plugins:[ChartJSPluginDatalabels],
  data() {
    return {
      chartData: {
        labels: ['0-3세','3-6세','7-12세','13세이상', 'NA'],
        datasets: [
          {
            label: ['연령별'],
            backgroundColor:["#F2F6FF", "#E6F2FF","#CCE6FF","#B3D9FF","#99CCFF","#80BFFF"] ,
            data: [4, 42, 14, 23,33,44]
          },
        ]
      },
      // for donutchart options
      //---------------
      // options: {
      //   legend: { display: false }, // datasets의 labels를 선언함으로 생기는 legend를 비표시로
      //   cutoutPercentage: 85, // 차트의 굵기
      //   elements: {
      //     arc: {
      //       roundedCornersFor: 0
      //     },
      //     center: [
      //       {
      //         text: '60',
      //         font: 'bolder 4rem sans-serif',
      //         fillStyle: '#354abd'
      //       }, 
      //       {
      //         text: 'Point',
      //         font: 'bold 0.8rem sans-serif',
      //         fillStyle: '#222222'
      //       },
      //       {
      //         text: '昨日比＋5',
      //         font: 'bold 1rem sans-serif',
      //         fillStyle: '#222222'
      //       }
      //     ]
      //   }
      // }
      options: {
        maintainAspectRatio :false,
        responsive: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
      }
      //---------------
    }
  },
  // mounted:{
  //   // chart.canvas.parentNode.style.height = '128px';
  //   // chart.canvas.parentNode.style.width = '128px';
  // },
  method:{
    // getTotalColorFirstHalfHexArray(){
    //   return ["#F2F6FF", "#E6F2FF","#CCE6FF","#B3D9FF","#99CCFF" ,
    //           "#80BFFF"]
    // },
    // getTotalColorLastHalfHexArray(){
    //   return ["#66B3FF","#4DA6FF","#3399FF","#1A8CFF" ,
    //           "#0080FF", "#0073E6","#0066CC"]
    // },
  }
}


// export default {
//   extends: Doughnut,
//   props: ['options'],
//   name:'donutchart',

//   props: ['data', 'options'],
//   mounted () {
//     this.renderChart(this.data, this.options)
//   },
//   watch: {
//     data: function () {
//       this._chart.destroy()
//       this.renderChart(this.data, this.options)
//     }
//   }
// }
</script>
