<template>
    <div>
        <div style="height:40px" />
        <div class="d-flex justify-content-center">
            <div class="d-flex flex-column"  >
                <div style="height:40px" />

                <div class="d-flex justify-content-center" style="width:700px">

                    <div style="height:40px" />

                    <div style="width:100%" align="center"  >
                        <div class="d-flex flex-column"  >
                            <div class="d-flex justify-content-left" style="width:700px">
                                <p style="font-weight: bold"><i class="fas fa-check"></i>회원 가입 이후 절차 안내</p>
                            </div>
                            <div class="d-flex" style="width:700px">

                                <div style="height:40px" />


                                <div class="z-depth-1" style="font-size:13px; background-color: white; border:1px solid #ddd;height:220px;overflow:auto;padding:5px" >
                                    <dl style='text-align:left'>
                                        <dt style='font-weight:normal'>
                                        회원가입을 위해 입력하신 <b>email</b> 과 <b>면허 번호</b>
                                        그리고 의뢰 기관 정보를 근거로 중앙 진단 기관 관리자가 승인됩니다.
                                        </dt>
                                        <br>
                                        <dt  style='font-weight:normal'>
                                        <b>Email 주소 확인</b>을 위한 메일이 시스템 상에서 발송되었으니,
                                        메일에 표시된 <font color='#ff0000'><b>인증용 링크 클릭</b></font> 부탁드립니다. <br>
                                        미진단 환자 의뢰를 위한, 최소한의 인증 절차이오니 양해 부탁드립니다.
                                        </dt>
                                    </dl>

                                    <button href="#" class="btn btn-primary btn-sm btn-search-action" id="btn_goto_login"
                                        @click.self.prevent="this.gotologin()"
                                    >
                                        LOGIN  화면으로 이동
                                    </button>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script >

import { useRouter } from 'vue-router'


export default {
    name:'UserSignUp',
    //emits:['user.privacyagreed'],
    setup(){

        const router = useRouter ()


        return {router}
    },
    data(){

        //console.log("data:hospital_info")
        return { }
    },
    created(){

    },
    methods:{
        gotologin(){
            this.router.push({name:'Login'})
        }
    }
}

</script>

<style>
</style>
