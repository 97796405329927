<template>
<div style="height:40px" />
<div style="width:100%" align="center"  >
  <form @submit.prevent="onSubmit" >
    <div style="width:400px">
    <h1 class="h3 mb-3 fw-normal">Please sign in</h1>
    <input v-model = "login_info.email" type="email" class="form-control" placeholder="Email" required  :class="{error:v$.email.$error}" @input="v$.email.$touch" />
    <input v-model = "login_info.password" type="password" class="form-control"  placeholder="Password" required  :class="{error:v$.password.$error}" @input="v$.password.$touch"  />
    <button class="w-100 btn btn-lg btn-primary" type="submit">Sign in</button>
    <div style="padding:3px;">
      <!--a href="/usersignup">Password Reset Request</a-->
	<button href="#" class="btn btn-link btn-sm" v-on:click.prevent="this.moveToPasswordReset()">
      Password Reset Request
	</button>
    </div>
    </div>
  </form>
</div>
</template>

<script>
import { reactive, computed } from 'vue'
import { useRouter } from 'vue-router'

import useVuelidate from '@vuelidate/core'
import { required,email, minLength } from "@vuelidate/validators";

import api from '@/services/base/index.js'

import {mapActions} from 'vuex'
/* eslint-disable */

const userStore = 'userStore'

export default {
  name: 'Login',
  //emits:['updateLoginStatus'],
  setup(){
    const login_info = reactive({
      email: '',
      password:''
    });
    const login_rule = computed(() =>{
        return {
            email: { required, email }, // Matches state.contact.email
            password:{required, minLength: minLength(6) },
        };
    });

    const router = useRouter ()

    const v$ = useVuelidate(login_rule, login_info);
    return {v$, login_info, router}

  },
  methods: {
    ...mapActions(userStore,['login']),  //call vuex/userStore.js
    async moveToPasswordReset(){
      api.getBaseUrl()
      .then(response =>{
        let url = response + 'user/password_reset/';
        window.location.href = url;
      })
    },
    async onSubmit() {
      try {
        console.log('Login.vue/onSubmit():')

        // call vuex/actions/login (this.login means vuex/actions/login function. In main.js import store from '@/vuex/store.js)'
        let loginResult = await this.login({email: this.login_info.email, password: this.login_info.password})
        //alert('Login.vue/onSubmit result of userStore.login loginResult: '+loginResult)
        if (!loginResult){
          return;
          // throw new Error ('login is failed')
        }
        //alert ('Login.vue before this.router.push')

       // const loggedObj = this.$store.getters['userStore/getLoginObj'];
        // console.log('Login.vue: onSubmit() loggedObj:'+JSON.stringify(loggedObj));
        //console.log ('Login.vue from this.getters[userStore/getLoginObj]:'+JSON.stringify(loginResult))

        // For distribution of loginResult to other store
        //==========================================================
        this.$store.commit('patientReqStore/setLoginObj',loginResult)
        // test code to verify the distributed loginObj
        // const fromPatientReqStore = this.$store.getters['patientReqStore/getLoginObj']
        //alert ('Login:onSubmit fromPatientReqStore:'+JSON.stringify(fromPatientReqStore))
        //==========================================================


        this.router.push({name:'ReqMain'});

      } catch (err) {
        console.error(err)
        alert(err)
        this.router.push({name:'Login'});
      } finally {
        this.initForm()

      }
    }, // end of onSubmit
    initForm(){
      this.login_info.email =''
      this.login_info.password=''
    }
  }, // end of methods
}
</script>

<style>
</style>

